import React, {useState, useEffect} from 'react'
import { ColorModeContext, useMode } from './theme';
import { tokens } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Topbar from "./scenes/global/Topbar";
import Dashboard from "./scenes/dashboard";

// COMPONENTES
import Sidebark9 from './scenes/global/Sidebark9';
import Login from './pages/login/Login';

// CATALOGOS
import Usuarios from './pages/catalogos/usuarios/Usuarios';
import Catalogos from './pages/catalogos/Catalogos';
import Documentos from './pages/catalogos/documentos/Documentos';
import Clientes from './pages/catalogos/clientes/Clientes';
import ClienteVista from './pages/catalogos/clientes/clientes_vista/ClienteVista';
import Tractores from './pages/catalogos/tractores/Tractores';
import TractorVista from './pages/catalogos/tractores/tractor_vista/TractorVista';
import Operadores from './pages/catalogos/operadores/Operadores';
import OperadorVista from './pages/catalogos/operadores/operador_vista/OperadorVista';
import Perfiles from './pages/catalogos/perfiles/Perfiles';
import Carrier from './pages/catalogos/carrier/Carrier';
import Warehouse from './pages/catalogos/warehouse/Warehouse';
import CarrierVista from './pages/catalogos/carrier/carrier_vista/CarrierVista';
import OrdenesTransporte from './pages/transporte/OrdenesTransporte';
import CrearOrdenTransporte from './pages/transporte/ordenes/CrearOrdenTransporte';
import ControlOrdenes from './pages/transporte/ControlOrdenes';

function App() {

  const [theme, colorMode] = useMode();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  // ===============================================  
  // LOGIN
  // =============================================== 

  const [showLogin, setShowLogin] = useState(true);

  useEffect(() => {

    if(Cookies.get('login') === "1"){

      setShowLogin(false);

      const ruta = Cookies.get('modulo');

      navigate(`/${ruta}`);

    }

  },[])

  // ===============================================

  return (

    <ColorModeContext.Provider value={colorMode}>

      <ThemeProvider theme={theme}>

        <CssBaseline>

          <div className='app'>

            {showLogin ? null : <Sidebark9 />}

            <main 
              className='content' 
              style={{
                backgroundColor: colors.primary[1000], 
                minHeight: '100vh',
                overflow: 'auto'
              }}
            >

              {showLogin ? null : <Topbar setShowLogin={setShowLogin}/>}

              <Routes>

                {/* INICIO */}
                <Route path='/' element={<Login setShowLogin={setShowLogin}/>}/>
                <Route path='/dashboard' element={<Dashboard/>}/>

                {/* CATALOGOS */}
                <Route path='/catalogos' element={<Catalogos/>}/>

                {/* USUARIOS */}
                <Route path='/usuarios' element={<Usuarios/>}/>

                {/* DOCUMENTOS */}
                <Route path='/documentos' element={<Documentos/>}/>

                {/* CLIENTES */}
                <Route path='/clientes' element={<Clientes/>}/>
                <Route path='/cliente_detalles' element={<ClienteVista/>}/>

                {/* TRACTORES */}
                <Route path='/tractores' element={<Tractores/>}/>
                <Route path='/tractor_detalles' element={<TractorVista/>}/>

                {/* OPERADORES */}
                <Route path='/operadores' element={<Operadores/>}/>
                <Route path='/operador_detalles' element={<OperadorVista/>}/>

                {/* PERFILES */}
                <Route path='/perfiles' element={<Perfiles/>}/>

                {/* CARRIER */}
                <Route path='/carrier' element={<Carrier/>}/>
                <Route path='/carrier_detalles' element={<CarrierVista/>}/>

                {/* WAREHOUSE */}
                <Route path='/warehouse' element={<Warehouse/>}/>

                {/* TRANSPORTE */}
                <Route path='/ordenes_transporte' element={<OrdenesTransporte/>}/>
                <Route path='/crear_orden_transporte' element={<CrearOrdenTransporte/>}/>
                <Route path='/datos_orden' element={<ControlOrdenes/>}/>

              </Routes>

            </main>

          </div>

        </CssBaseline>

      </ThemeProvider>

    </ColorModeContext.Provider>

  )
  
}

export default App