import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal, Paper, Typography, Box, Container, Grid } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {api} from '../../../../config/axios';
import Swal from 'sweetalert2';
import { styled } from '@mui/system';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';
import { tiposCarga, tiposOperacion } from '../../../../data/catalogos';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';

// COMPONENTES
import MyAutoComplete from '../../../../components/inputs/MyAutoComplete';
import MyAutoCompleteSublabels from '../../../../components/inputs/MyAutoCompleteSublabels';
import MyDateInput from '../../../../components/inputs/MyDateInput';
import MyHourInput from '../../../../components/inputs/MyHourInput';

const ModalEditarStop = ({ open, onClose, refresh, formValues }) => {

    // ===============================================
    // ESTADO PARA INITIAL VALUES
    // ===============================================

    const [initialValues, setInitialValues] = useState({

        rel_carrier: '', 
        tipo_carga: '', 
        tipo_operacion: '',
        warehouse_origen: '',
        warehouse_destino: '',
        fecha_cita: '',
        hora_cita: '',

    });

    // ===============================================
    // BODY DEL MODAL
    // ===============================================

    const ScrollableContainer = styled(Container)({
        maxHeight: '60vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });

    // ===============================================
    // VALIDAR SI SE VE DESDE UN MOVIL
    // ===============================================

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // FUNCION PARA BUSCAR CARRIERS
    // ===============================================

    const [carrier, setCarrier] = useState([]);

    const buscarCarriers = async () => {

        const response = await api.get('carrier/select');

        if(response.data !== "Sin Resultados"){

            setCarrier(response.data);

        }else{

            setCarrier([]);

        }

    }

    // ===============================================
    // FUNCION PARA BUSCAR WAREHOUSE
    // ===============================================

    const [warehouse, setWarehouse] = useState([]);

    const buscarWarehouse = async () => {

        const response = await api.get('warehouse/select');

        if(response.data !== "Sin Resultados"){

            setWarehouse(response.data);

        }else{

            setWarehouse([]);

        }

    }

    // ===============================================
    // USE MEMO PARA CATALOGOS
    // ===============================================

    const buscarDatosCatalogos = useMemo(() => {

        return {

            buscarCarriers,
            buscarWarehouse

        }

    },[]);

    // ===============================================
    // USE EFFECT PARA LLAMAR FUNCIONES
    // ===============================================

    useEffect(() => {

        // LLAMAMOS LAS FUNCIONES DESDE USEMEMO

        buscarDatosCatalogos.buscarCarriers();
        buscarDatosCatalogos.buscarWarehouse();

        // ACTUALIZAMOS EL VALOR DEL FORMULARIO
        
        setInitialValues(formValues);

    },[formValues]);

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = (values) => {

        const url = 'transporte/ordenes/stops/update';

        // MANDAMOS EL POST

        api.put(url, values)
        .then((response) => {

            if(response.data.success === true){

                onClose('editar');

                refresh();

                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                })

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    // ===============================================

    return (

        <Modal
            open={open}
            onClose={() => onClose('editar')}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >

            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={Yup.object({
                    rel_carrier: Yup.string().required('Requerido'),
                    tipo_carga: Yup.string().required('Requerido'),
                    tipo_operacion: Yup.string().required('Requerido'),
                    warehouse_origen: Yup.string().required('Requerido'),
                    warehouse_destino: Yup.string().required('Requerido'),
                    fecha_cita: Yup.string().required('Requerido'),
                    hora_cita: Yup.string().required('Requerido'),
                })}
                onSubmit={(values) => {
                    handleSubmit(values)
                }}
            >
            {({ isSubmitting }) => (

                <Paper
                    sx={{ 
                        width: isNonMobile ? `60%` : '90%', 
                        p: 3, 
                        bgcolor: colors.primary[1000] 
                    }}
                >

                    <Form>

                        {/* HEADER DEL MODAL */}

                        <Typography variant="h3" align="center" marginBottom={1}>
                            Editar Stop
                        </Typography>

                        {/* BODY DEL MODAL */}

                        <ScrollableContainer>

                            <Grid
                                container
                                spacing={2}
                                paddingBottom={2}
                                marginTop={2}
                            >

                                {/* CARRIER */}

                                <Grid item xs={4}>

                                    <MyAutoComplete
                                        name="rel_carrier"
                                        label="Carrier"
                                        options={carrier}
                                    />

                                </Grid>

                                {/* TIPO CARGA */}

                                <Grid item xs={4}>

                                    <MyAutoComplete
                                        name="tipo_carga"
                                        label="Tipo Carga"
                                        options={tiposCarga}
                                    />

                                </Grid>

                                {/* TIPO OPERACION */}

                                <Grid item xs={4}>

                                    <MyAutoComplete
                                        name="tipo_operacion"
                                        label="Tipo Operacion"
                                        options={tiposOperacion}
                                    />

                                </Grid>

                                {/* WAREHOUSE ORIGEN */}

                                <Grid item xs={6}>

                                    <MyAutoCompleteSublabels
                                        name="warehouse_origen"
                                        label="Origen"
                                        options={warehouse}
                                    />

                                </Grid>

                                {/* FECHA CITA */}

                                <Grid item xs={3}>

                                    <MyDateInput
                                        name="fecha_cita"
                                        label="Fecha Cita"
                                    />

                                </Grid>

                                {/* HORA CITA */}

                                <Grid item xs={3}>

                                    <MyHourInput
                                        name="hora_cita"
                                        label="Hora Cita"
                                    />

                                </Grid>

                                {/* WAREHOUSE DESTINO */}

                                <Grid item xs={6}>

                                    <MyAutoCompleteSublabels
                                        name="warehouse_destino"
                                        label="Destino"
                                        options={warehouse}
                                    />

                                </Grid>

                                {/* FECHA LLEGADA */}

                                <Grid item xs={3}>

                                    <MyDateInput
                                        name="fecha_llegada"
                                        label="Fecha Llegada"
                                    />

                                </Grid>

                                {/* HORA LLEGADA */}

                                <Grid item xs={3}>

                                    <MyHourInput
                                        name="hora_llegada"
                                        label="Hora Llegada"
                                    />

                                </Grid>

                            </Grid>

                        </ScrollableContainer>

                        {/* FOOTER DEL MODAL */}

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>

                            <Button variant="contained" color="primary" onClick={() => onClose('editar')}>
                                Cancelar
                            </Button>

                            <Button 
                                type="submit" 
                                variant="contained" 
                                sx={{color: "white"}} 
                                color="success" 
                                startIcon={<SaveIcon />}
                                disabled={isSubmitting}
                            >
                                Guardar
                            </Button>

                        </Box>

                    </Form>

                </Paper>

            )}
            </Formik>

        </Modal>

    )

}

export default ModalEditarStop