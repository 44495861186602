/*=============================================
IMPORTAR ICONOS
=============================================*/

import AppsIcon from '@mui/icons-material/Apps';
import GroupIcon from '@mui/icons-material/Group';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import DescriptionIcon from '@mui/icons-material/Description';

/*=============================================
COLECCION DE ICONOS
=============================================*/

export const iconos = {

    AppsIcon: AppsIcon,
    GroupIcon: GroupIcon,
    AgricultureIcon: AgricultureIcon,
    EmojiTransportationIcon: EmojiTransportationIcon,
    BadgeIcon: BadgeIcon,
    AccountCircleIcon: AccountCircleIcon,
    PermContactCalendarIcon: PermContactCalendarIcon,
    LocalShippingIcon: LocalShippingIcon,
    AddRoadIcon: AddRoadIcon,
    DescriptionIcon: DescriptionIcon

}

/*=============================================
COLECCION DE MODULOS
=============================================*/

export const modulos = [

    // SECCION PARA LOS CATALOGOS

    { type: 'subheader', label: 'Catalogo', icono: 'AppsIcon' },

    { 
        type: 'menu',
        seccion: 'Catalogo',
        modulo: 'Documentos', 
        icono: 'DescriptionIcon', 
        url: 'documentos' 
    },
    { 
        type: 'menu',
        seccion: 'Catalogo', 
        modulo: 'Clientes', 
        icono: 'GroupIcon', 
        url: 'clientes' 
    },
    { 
        type: 'menu',
        seccion: 'Catalogo', 
        modulo: 'Tractores', 
        icono: 'AgricultureIcon', 
        url: 'tractores' 
    },
    { 
        type: 'menu',
        seccion: 'Catalogo',
        modulo: 'Operadores', 
        icono: 'PermContactCalendarIcon', 
        url: 'operadores' 
    },
    { 
        type: 'menu',
        seccion: 'Catalogo',
        modulo: 'Carrier', 
        icono: 'LocalShippingIcon', 
        url: 'carrier' 
    },
    { 
        type: 'menu',
        seccion: 'Catalogo',
        modulo: 'Warehouse', 
        icono: 'EmojiTransportationIcon', 
        url: 'warehouse' 
    },
    { 
        type: 'menu',
        seccion: 'Catalogo', 
        modulo: 'Perfiles', 
        icono: 'BadgeIcon', 
        url: 'perfiles' 
    },
    { 
        type: 'menu',
        seccion: 'Catalogo',
        modulo: 'Usuarios', 
        icono: 'AccountCircleIcon', 
        url: 'usuarios' 
    },

    // SECCION PARA TRANSPORTE

    { type: 'subheader', label: 'Transporte', icono: 'EmojiTransportationIcon' },

    { 
        type: 'menu',
        seccion: 'Transporte', 
        modulo: 'Ordenes T', 
        icono: 'AddRoadIcon', 
        url: 'ordenes_transporte' 
    },

    // SECCION PARA LAS ORDENES

    { type: 'subheader', label: 'Viajes', icono: 'EmojiTransportationIcon' },

    { 
        type: 'menu',
        seccion: 'Viajes', 
        modulo: 'Control Viajes', 
        icono: 'AddRoadIcon', 
        url: 'viajes' 
    },

];

