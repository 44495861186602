import React, { useState } from 'react';
import { Typography, Box, Grid, BottomNavigation, BottomNavigationAction } from "@mui/material";
import { useLocation } from 'react-router-dom';
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';

// ICONOS
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import PaidIcon from '@mui/icons-material/Paid';
import AnnouncementIcon from '@mui/icons-material/Announcement';

// COMPONENTES
import EditarOperador from '../operadores_datos/EditarOperador';
import OperadorDatosNomina from '../operador_datos_nomina/OperadorDatosNomina';
import OperadorDocumentos from '../operador_documentos/OperadorDocumentos';
import Incidencias from '../operador_incidencias/Incidencias';

const OperadorVista = () => {

    // ===============================================
    // OBTENER INFORMACION DEL CURSO
    // ===============================================

    const location = useLocation();
    const { data } = location.state;

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // -----------------------------------------------

    const fontColor = colors.redAccent[500];
    const iconColor = colors.redAccent[500];

    // ===============================================
    // CATALOGO DE COMPONENTES
    // ===============================================

    const views = {

        datos: EditarOperador,
        nomina: OperadorDatosNomina,
        documentos: OperadorDocumentos,
        incidencias: Incidencias
    }

    // ===============================================
    // OPCION DE COMPONENTE 
    // ===============================================

    const [opcion, setOpcion] = useState('datos');

    // ===============================================
    // COMPONENTE DINAMICO
    // ===============================================

    const ComponenteActual = views[opcion];

    // ===============================================

    return (

        <Box m="20px">

            <Grid container spacing={2} padding={2}>

                {/* HEADEAR */}

                <Grid item xs={12}>

                    <Typography variant="h1" align="center" fontWeight={'bold'}>
                        Operador: {data.nombres + " " + data.apellidos}
                    </Typography>

                </Grid>

                {/* SECCION DE MENUS */}

                <Grid item xs={12}>

                    <BottomNavigation
                        showLabels
                        value={opcion}
                        onChange={(event, newValue) => {
                            setOpcion(newValue)
                        }}
                        sx={{borderRadius: '10px'}}
                    >
                        {/* DATOS DEL OPERADOR */}
                        <BottomNavigationAction
                            label="Operador"
                            value='datos'
                            icon={<AccountCircleIcon style={{ color: opcion === 'datos' ? iconColor : 'inherit' }} />}
                            style={{ color: opcion === 'datos' ? fontColor : 'inherit' }}
                        />
                        {/* DOCUMENTOS DEL OPERADOR */}
                        <BottomNavigationAction
                            label="Documentos"
                            value='documentos'
                            icon={<DescriptionIcon style={{ color: opcion === 'documentos' ? iconColor : 'inherit' }} />}
                            style={{ color: opcion === 'documentos' ? fontColor : 'inherit' }}
                        />
                        {/* INCIDENCIAS */}
                        <BottomNavigationAction
                            label="Incidencias"
                            value='incidencias'
                            icon={<AnnouncementIcon style={{ color: opcion === 'incidencias' ? iconColor : 'inherit' }} />}
                            style={{ color: opcion === 'incidencias' ? fontColor : 'inherit' }}
                        />
                        {/* DATOS DE NOMINA */}
                        <BottomNavigationAction
                            label="Datos Nomina"
                            value='nomina'
                            icon={<PaidIcon style={{ color: opcion === 'nomina' ? iconColor : 'inherit' }} />}
                            style={{ color: opcion === 'nomina' ? fontColor : 'inherit' }}
                        />
                    </BottomNavigation>

                </Grid>

                {/* AREA DEL COMPONENTE A MOSTRAR */}

                <Grid item xs={12}>

                    <ComponenteActual
                        idOperador={data.id}
                    />

                </Grid>

            </Grid>

        </Box>

    )

}

export default OperadorVista