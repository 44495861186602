import React from 'react';
import { Button, Modal, Paper, Typography, Box, Grid  } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';
import useMediaQuery from "@mui/material/useMediaQuery";
import { sendFormData } from '../../../../config/axios';
import Swal from 'sweetalert2';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';

// COMPONENTES
import MyInputFile from '../../../../components/inputs/MyInputFile';
import TextInput from '../../../../components/inputs/TextInput';

const ModalCrearDocumento = ({ open, onClose, refresh, idCarrier }) => {

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // VALIDAR SI SE ESTA VIENDO DESDE UN DISPOSITVO MOVIL
    // ===============================================

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // ===============================================
    // SUBMIT DEL MODAL MULTIPLES ARCHIVOS
    // ===============================================

    const handleSubmit = async (values) => {

        const url = 'carrier/documentos/crear';

        try{

            values.rel_carrier = idCarrier;

            const result = await sendFormData(url, values);

            if(result.success === true){

                onClose();

                Swal.fire({
                    icon: 'success',
                    title: result.message,
                })

                refresh();

            }

        }catch(error){

            console.error("Upload failed:", error);

        }

    };

    // ===============================================

    return (

        <Modal
            open={open}
            onClose={onClose}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >

            <Formik
                initialValues={{ 
                    nombre_documento: '',
                    ruta: '' 
                }}
                validationSchema={Yup.object().shape({
                    nombre_documento: Yup.string().required('Requerido'),
                    ruta: Yup.mixed()
                    .test('fileType', 'Formato de archivo no válido', (value) => {
                    return value && ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'].includes(value.type);
                    })
                    .test('fileSize', 'El archivo es demasiado grande', (value) => {
                    return value && value.size <= 10485760; // 10MB
                    })
                })}
                onSubmit={handleSubmit}
            >
            {({ isSubmitting }) => (

                <Paper
                    sx={{ 
                        width: isNonMobile ? `30%` : '90%', 
                        p: 3, 
                        bgcolor: colors.primary[1000] 
                    }}
                >

                    {/* HEADER DEL MODAL */}

                    <Typography variant="h4" align="center" mb={2}>
                        Subir Documento
                    </Typography>

                    {/* CUERPO DEL MODAL */}

                    <Form>

                        <Grid container spacing={2}>

                            {/* NOMBRE DEL DOCUMENTO */}

                            <Grid item xs={12}>

                                <TextInput
                                    name="nombre_documento"
                                    label="Nombre Documento"
                                />

                            </Grid>

                            {/* ARCHIVO DEL DOCUMENTO */}

                            <Grid item xs={12}>

                                <MyInputFile
                                    name="ruta"
                                    label="Subir Archivo"
                                    accept=".jpg, .jpeg, .png, .pdf, .xlsx"
                                />

                            </Grid>

                        </Grid>

                        {/* FOOTER DEL MODAL */}

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>

                            <Button variant="contained" color="primary" onClick={onClose}>
                                Cancelar
                            </Button>

                            <Button type="submit" variant="contained" sx={{color: "white"}} color="success" startIcon={<SaveIcon />} disabled={isSubmitting}>
                                    Guardar
                            </Button>

                        </Box>

                    </Form>

                </Paper>

            )}
            </Formik>

        </Modal>

    )

}

export default ModalCrearDocumento