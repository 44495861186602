import React from 'react';
import { Button, Modal, Paper, Typography, Box, Container, Grid  } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {sendFormData} from '../../../../config/axios';
import Swal from 'sweetalert2';
import { styled } from '@mui/system'
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';
import Cookies from 'js-cookie';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';

// COMPONENTES
import TextInput from '../../../../components/inputs/TextInput';
import MyDateInput from '../../../../components/inputs/MyDateInput';
import MyInputFile from '../../../../components/inputs/MyInputFile';

const ModalCrearPlacas = ({open, onClose, refresh, idTractor}) => {

    // ===============================================
    // BODY DEL MODAL
    // ===============================================

    const ScrollableContainer = styled(Container)({
        maxHeight: '40vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });

    // ===============================================
    // VALIDAR SI SE VE DESDE UN MOVIL
    // ===============================================

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = async (values) => {

        const url = 'tractores/placas/crear';

        // TERMINAMOS DE AGREGAR LOS DATOS FALTANTES

        values.rel_tractor = idTractor;
        values.rel_user = Cookies.get('idUser');

        const result = await sendFormData(url, values);

        if(result.success === true){

            onClose();

            refresh();

            Swal.fire({
                icon: 'success',
                title: result.message,
            })

        }

    }

    // ===============================================

    return (

        <Modal
            open={open}
            onClose={onClose}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >

            <Formik
                initialValues={{
                    num_placa: '', 
                    fecha_expiracion: '', 
                    ruta: '',
                }}
                validationSchema={Yup.object({
                    num_placa: Yup.string().required('Requerido'),
                    fecha_expiracion: Yup.string().required('Requerido'),
                    ruta: Yup.mixed()
                    .test('fileType', 'Formato de archivo no válido', (value) => {
                    return value && ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'].includes(value.type);
                    })
                    .test('fileSize', 'El archivo es demasiado grande', (value) => {
                    return value && value.size <= 10485760; // 10MB
                    })
                })}
                onSubmit={(values) => {
                    handleSubmit(values)
                }}
            >
            {({ isSubmitting }) => (

                <Paper
                    sx={{ 
                        width: isNonMobile ? `30%` : '90%', 
                        p: 3, 
                        bgcolor: colors.primary[1000] 
                    }}
                >

                    <Form encType='multipart/form-data'>

                        {/* HEADER DEL MODAL */}

                        <Typography variant="h3" align="center" marginBottom={1}>
                            Agregar Placas
                        </Typography>

                        {/* BODY DEL MODAL */}

                        <Grid
                            container
                            spacing={2}
                            paddingBottom={2}
                            marginTop={2}
                        >

                            {/* NUMERO DE PLACA */}

                            <Grid item xs={12}>

                                <TextInput
                                    name="num_placa"
                                    label="Ingrese Numero de Placa"
                                />

                            </Grid>

                            {/* FECHA DE EXPIRACION */}

                            <Grid item xs={12}>

                                <MyDateInput
                                    name="fecha_expiracion"
                                    label="Fecha De Expiracion"
                                />

                            </Grid>

                            {/* SUBIR DOCUMENTO */}

                            <Grid item xs={12}>

                                <MyInputFile
                                    name="ruta"
                                    label="Subir Archivo"
                                />

                            </Grid>

                        </Grid>

                        {/* FOOTER DEL MODAL */}

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>

                            <Button variant="contained" color="primary" onClick={() => onClose('crear')}>
                                Cancelar
                            </Button>

                            <Button 
                                type="submit" 
                                variant="contained" 
                                sx={{color: "white"}} 
                                color="success" 
                                startIcon={<SaveIcon />}
                                disabled={isSubmitting}
                            >
                                Guardar
                            </Button>

                        </Box>

                    </Form>

                </Paper>

            )}
            </Formik>

        </Modal>

    )

}

export default ModalCrearPlacas