import Cookies from 'js-cookie';

// FUNCION PARA MARCAR LA URL ACTUAL

export function marcarURL(url){

    // SETEAR RUTA DONDE NOS ENCONTRAMOS PARA EL REFRESH

    const expires = new Date();

    expires.setDate(expires.getDate() + 1);

    Cookies.set('modulo', url, {expires});

}

// FUNCION PARA HACER LOGOUT

export function logOutFunction(setShowLogin){

    const expires = new Date();

    expires.setDate(expires.getDate() + 1);

    Cookies.remove('token');
    Cookies.remove('modulo');
    Cookies.remove('menu');
    Cookies.remove('idUser');
    Cookies.remove('perfil');
    Cookies.set('login', "0", {expires});
    
    //setShowLogin(true);

    window.location.href = '/';

}

// FUNCION PARA FORMATEAR FECHA

export function formatDate(fechaOriginal){

    const fecha = new Date(fechaOriginal);
        
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Se suma 1 porque los meses en JavaScript van de 0 a 11.
    const anio = fecha.getFullYear();
  
    return `${dia}-${mes}-${anio}`;

}

// FUNCION PARA FORMATEAR LPAD

export function lpad(str, length, padChar){

    str = String(str);

    while (str.length < length) {

        str = padChar + str;

    }
    
    return str;

}