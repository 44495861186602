import React, {useEffect, useState} from "react";
import { marcarURL } from "../../config/functions";
import Cookies from "js-cookie";
import DashboardAdmin from "../../pages/catalogos/dashboard/DashboardAdmin";
import DashboardEmpleado from "../../pages/catalogos/dashboard/DashboardEmpleado";
import DashboardOperador from "../../pages/catalogos/dashboard/DashboardOperador";

const Dashboard = () => {

  // ===============================================
  // CATALOGO DE COMPONENTES
  // ===============================================

  const views = {

    dashboardAdmin: DashboardAdmin,
    dashboardEmpleado: DashboardEmpleado,
    dashboardOperador: DashboardOperador

  }

  // ===============================================
  // OPCION DE COMPONENTE 
  // ===============================================

  const [opcion, setOpcion] = useState('dashboardEmpleado');

  // ===============================================
  // COMPONENTE DINAMICO
  // ===============================================

  const ComponenteActual = views[opcion];

  // ===============================================

  useEffect(() => {

    marcarURL('dashboard');

    const perfil = Cookies.get('perfil');

    if(perfil === 'Sistemas' || perfil === 'Gerencia'){
  
      setOpcion('dashboardAdmin'); 

    }
    else if(perfil === 'Operador'){

      setOpcion('dashboardOperador'); 

    }else{

      setOpcion('dashboardEmpleado');

    }

  },[])



  return (

    <>

      {/* <ComponenteActual/> */}
      <DashboardAdmin/>

    </>

  );
};

export default Dashboard;