import { Box, Button, IconButton, Typography, useTheme, Grid } from "@mui/material";
import { tokens } from "../../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../../components/Header";
import LineChart from "../../../components/LineChart";
import StatBox from "../../../components/StatBox";
import React from "react";

const DashboardAdmin = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (

        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

                <Box>
                <Button
                    sx={{
                    backgroundColor: colors.redAccent[500],
                    color: 'white',
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                    }}
                >
                    <DownloadOutlinedIcon sx={{ mr: "10px" }} />
                    Download Reports
                </Button>
                </Box>
            </Box>

            <Grid container spacing={2}>

                {/* FILA 1 */}

                <Grid item xs={6} md={3}>

                    <Box
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    padding={2}
                    >
                    <StatBox
                        title="12,361"
                        subtitle="Emails Sent"
                        progress="0.75"
                        increase="+14%"
                        icon={
                        <EmailIcon
                            sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                        />
                        }
                    />
                    </Box>

                </Grid>

                {/* ==================== */}

                <Grid item xs={6} md={3}>

                    <Box
                    padding={2}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    >
                    <StatBox
                        title="431,225"
                        subtitle="Sales Obtained"
                        progress="0.50"
                        increase="+21%"
                        icon={
                        <PointOfSaleIcon
                            sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                        />
                        }
                    />
                    </Box>

                </Grid>

                {/* ==================== */}

                <Grid item xs={6} md={3}>

                    <Box
                    padding={2}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    >
                    <StatBox
                        title="32,441"
                        subtitle="New Clients"
                        progress="0.30"
                        increase="+5%"
                        icon={
                        <PersonAddIcon
                            sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                        />
                        }
                    />
                    </Box>

                </Grid>

                {/* ==================== */}

                <Grid item xs={6} md={3}>

                    <Box
                    padding={2}
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    >
                    <StatBox
                        title="1,325,134"
                        subtitle="Traffic Received"
                        progress="0.80"
                        increase="+43%"
                        icon={
                        <TrafficIcon
                            sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                        />
                        }
                    />
                    </Box>

                </Grid>

                {/* FILA 2 */}

                <Grid 
                    item md={6} 
                    xs={12} 
                >
                    <Box
                        backgroundColor={colors.primary[400]}
                        padding={2}
                    >
                    <Box
                        mt="25px"
                        p="0 30px"
                        display="flex "
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box>
                            <Typography
                                variant="h5"
                                fontWeight="600"
                                color={colors.grey[100]}
                            >
                                Revenue Generated
                            </Typography>
                            <Typography
                                variant="h3"
                                fontWeight="bold"
                                color={colors.greenAccent[500]}
                            >
                                $59,342.32
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton>
                                <DownloadOutlinedIcon
                                sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                                />
                        </IconButton>
                        </Box>
                    </Box>
                    <Box height="250px" m="-20px 0 0 0">
                        <LineChart isDashboard={true} />
                    </Box>
                    </Box>

                </Grid>

                {/* ==================== */}

                <Grid 
                    item md={6} 
                    xs={12} 
                >
                    <Box
                        backgroundColor={colors.primary[400]}
                        padding={2}
                    >
                    <Box
                        mt="25px"
                        p="0 30px"
                        display="flex "
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box>
                        <Typography
                            variant="h5"
                            fontWeight="600"
                            color={colors.grey[100]}
                        >
                            Revenue Generated
                        </Typography>
                        <Typography
                            variant="h3"
                            fontWeight="bold"
                            color={colors.greenAccent[500]}
                        >
                            $59,342.32
                        </Typography>
                        </Box>
                        <Box>
                        <IconButton>
                            <DownloadOutlinedIcon
                            sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                            />
                        </IconButton>
                        </Box>
                    </Box>
                    <Box height="250px" m="-20px 0 0 0">
                        <LineChart isDashboard={true} />
                    </Box>
                    </Box>

                </Grid>

            </Grid>
     
        </Box>

    )

}

export default DashboardAdmin