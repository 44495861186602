import React, { useEffect, useState } from 'react';
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import {api} from '../../../config/axios';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

//COMPONENTES
import MiTabla from '../../../components/plantilla/MiTabla';
import ModalCrearStop from './modales/ModalCrearStop';
import ModalEditarStop from './modales/ModalEditarStop';

const Stops = ({ orderData }) => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // FUNCION PARA BUSCAR CLIENTES
    // ===============================================

    const [data, setData] = useState([]);

    const buscarStops = async () => {

        const response = await api.get(`transporte/ordenes/stops/${orderData.id}`);

        if(response.data !== "Sin Resultados"){

            setData(response.data);

        } else {

            setData([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        {
            field: "carrier",
            headerName: "Carrier",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "tipo_carga",
            headerName: "Carga",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "tipo_operacion",
            headerName: "Operacion",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "origen",
            headerName: "Origen",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "destino",
            headerName: "Destino",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },

    ];

    // ===============================================
    // ESTADO PARA MODAL
    // ===============================================

    const [showModal, setShowModal] = useState({

        crear: false,
        editar: false

    });

    // -----------------------------------------------

    const handleOpenModal = (target) => {

        setShowModal({ ...showModal, [target]: true});

    };

    // -----------------------------------------------
    
    const handleCloseModal = (target) => {

        setShowModal({ ...showModal, [target]: false});

    };

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const [formValues, setFormValues] = useState([]);

    const handleRowClick = (params) => {

        let data = params.row;

        data.fecha_cita = data.fecha_cita.split('T')[0];

        setFormValues(data);

        handleOpenModal('editar');

    }

    // ===============================================
    // USE EFFECT PARA LLAMAR A LAS FUNCIONES
    // ===============================================

    useEffect(() => {

        buscarStops();

    },[]);

    // ===============================================

    return (

        <Box m="20px">

            {/* BOTON PARA ABRIR MODAL */}

            <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.redAccent[400]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={() => handleOpenModal('crear')}
            >
                Agregar Stop
            </Button>

            {/* TABLA */}

            <MiTabla
                data={data}
                columns={columns}
                rowClick={handleRowClick}
            />

            {/* MODAL PARA CREAR STOP */}

            <ModalCrearStop
                open={showModal.crear}
                onClose={handleCloseModal}
                refresh={buscarStops}
                orderId={orderData.id}
            />

            {/* MODAL PARA EDITAR STOP */}

            <ModalEditarStop
                open={showModal.editar}
                onClose={handleCloseModal}
                refresh={buscarStops}
                formValues={formValues}
            />

        </Box>

    )

}

export default Stops