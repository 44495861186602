import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { api } from '../../../config/axios';
import { PDFViewer } from '@react-pdf/renderer';

// COMPONENTES
import Filtro from './Filtro';
import CartaRetiroK9 from './pdf/CartaRetiroK9';

const CartaRetiro = ({ orderData }) => {

    const [stopId, setStopId] = useState(0);
    const [stopData, setStopData] = useState({});

    // ===============================================
    // FUNCION PARA BUSCAR DATOS DEL STOP
    // ===============================================

    const buscarStop = async () => {

        const response = await api.get(`transporte/ordenes/stops/carta/retiro/${stopId}`);

        if(response.data !== "Sin Resultados"){

            setStopData(response.data[0]);

        } 

    }

    // ===============================================
    // USE EFFECT PARA LLAMAR FUNCIONES
    // ===============================================

    useEffect(() => {

        buscarStop();

    },[stopId]);

    // ===============================================

    return (

        <Grid container spacing={2}>

            {/* SECCION PARA EL FILTRO */}

            <Grid item xs={12}>

                <Filtro
                    idOrden={orderData.id}
                    select={setStopId}
                />

            </Grid>

            {/* SECCION PARA EL PDF */}

            <Grid item xs={12}>

                {
                    Object.keys(stopData).length > 0 && (

                        <Box>

                            <PDFViewer width="100%" height="500vh">
                                <CartaRetiroK9 stopData={stopData}/>
                            </PDFViewer>

                        </Box>

                    )
                }

            </Grid>

        </Grid>
        
    )

}

export default CartaRetiro