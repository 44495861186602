import React, { useEffect, useMemo, useState } from 'react';
import { Button, Typography, Box, Grid, Divider, Accordion, AccordionSummary, AccordionDetails, MenuItem } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {api} from '../../../config/axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";

// ICONOS
import SaveIcon from '@mui/icons-material/Save';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PaidIcon from '@mui/icons-material/Paid';

// COMPONENTES
import MyAutoComplete from '../../../components/inputs/MyAutoComplete';
import TextInput from '../../../components/inputs/TextInput';
import MyMoneyInput from '../../../components/inputs/MyMoneyInput';
import MyAutoCompleteSublabels from '../../../components/inputs/MyAutoCompleteSublabels';
import MyDateInput from '../../../components/inputs/MyDateInput';
import MyHourInput from '../../../components/inputs/MyHourInput';
import MySelect from '../../../components/inputs/MySelect';
import TablaIngresos from './tablas/TablaIngresos';

const CrearOrdenTransporte = () => {

    const navigate = useNavigate();

    // ===============================================
    // CONTROLADOR PARA INGRESOS
    // ===============================================

    const [arrayIngresos, setArrayIngresos] = useState([]);

    const agregarIngreso = (concepto, monto, moneda) => {

        const idUser = Cookies.get('idUser');

        setArrayIngresos(prevArray => [...prevArray, { concepto: concepto, monto: monto, moneda: moneda, rel_user: idUser}])

    }

    const eliminarIngreso = (index) => {

        setArrayIngresos(prevArray => prevArray.filter((_, i) => i !== index));

    }

    // ===============================================
    // CONTROLADOR PARA EGRESOS
    // ===============================================

    const [arrayEgresos, setArrayEgresos] = useState([]);

    const agregarEgreso = (concepto, monto, moneda) => {

        const idUser = Cookies.get('idUser');

        setArrayEgresos(prevArray => [...prevArray, { concepto: concepto, monto: monto,  moneda: moneda, rel_user: idUser}])

    }

    const eliminarEgreso = (index) => {

        setArrayEgresos(prevArray => prevArray.filter((_, i) => i !== index));

    }

    // ===============================================
    // FUNCION PARA BUSCAR OPERADORES
    // ===============================================

    const [operadores, setOperadores] = useState([]);

    const buscarOperadores = async () => {

        const response = await api.get('operadores/select');

        if(response.data !== "Sin Resultados"){

            setOperadores(response.data);

        }else{

            setOperadores([]);

        }

    }

    // ===============================================
    // FUNCION PARA BUSCAR TRACTORES
    // ===============================================

    const [tractores, setTractores] = useState([]);

    const buscarTractores = async () => {

        const response = await api.get('tractores/select');

        if(response.data !== "Sin Resultados"){

            setTractores(response.data);

        }else{

            setTractores([]);

        }

    }

    // ===============================================
    // FUNCION PARA BUSCAR CLIENTES
    // ===============================================

    const [clientes, setClientes] = useState([]);

    const buscarClientes = async () => {

        const response = await api.get('clientes/select');

        if(response.data !== "Sin Resultados"){

            setClientes(response.data);

        }else{

            setClientes([]);

        }

    }

    // ===============================================
    // FUNCION PARA BUSCAR WAREHOUSE
    // ===============================================

    const [warehouse, setWarehouse] = useState([]);

    const buscarWarehouse = async () => {

        const response = await api.get('warehouse/select');

        if(response.data !== "Sin Resultados"){

            setWarehouse(response.data);

        }else{

            setWarehouse([]);

        }

    }

    // ===============================================
    // USE MEMO PARA BUSCAR TRACTORES Y OPERADORES
    // ===============================================

    const buscarDatosCatalogos = useMemo(() => {

        return {

            buscarTractores,
            buscarOperadores,
            buscarClientes,
            buscarWarehouse

        }

    },[]);

    // ===============================================
    // USE EFFECT PARA LLAMAR FUNCIONES
    // ===============================================

    useEffect(() => {

        // LLAMAMOS LAS FUNCIONES DESDE USEMEMO

        buscarDatosCatalogos.buscarOperadores();
        buscarDatosCatalogos.buscarTractores();
        buscarDatosCatalogos.buscarClientes();
        buscarDatosCatalogos.buscarWarehouse();

    },[]);

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = (values) => {

        const url = 'transporte/ordenes/crear';

        // AGREGAMOS LOS DATOS FALTANTES

        values.ingresos = arrayIngresos;
        values.egresos = arrayEgresos;
        values.rel_user = Cookies.get('idUser');

        api.post(url, values)
        .then((response) => {

            if(response.data.success === true){

                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                })
                .then(() => {

                    navigate('/datos_orden', { state: { idOrden: response.data.idOrden } })

                })

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    // ===============================================

    return (

        <Box m="20px">

            <Formik
                initialValues={{
                    rel_cliente_operacion: '',
                    rel_cliente_facturacion: '',
                    rel_operador: '',
                    rel_tractor: '',
                    caja: '',
                    caja_placas: '',
                    warehouse_origen: '',
                    warehouse_destino: '',
                    fecha_cita: '',
                    hora_cita: '',
                    conceptoIngreso: '',
                    montoIngreso: '',
                    monedaIngreso: '',
                    conceptoEgreso: '',
                    montoEgreso: '',
                    monedaEgreso: ''
                }}
                validationSchema={Yup.object({
                    rel_cliente_operacion: Yup.string().required('Requerido'),
                    rel_cliente_facturacion: Yup.string().required('Requerido'),
                    rel_operador: Yup.string().required('Requerido'),
                    rel_tractor: Yup.string().required('Requerido'),
                    caja: Yup.string().required('Requerido'),
                    caja_placas: Yup.string().required('Requerido'),
                    warehouse_origen: Yup.string().required('Requerido'),
                    warehouse_destino: Yup.string().required('Requerido'),
                    fecha_cita: Yup.string().required('Requerido'),
                    hora_cita: Yup.string().required('Requerido'),
                })}
                onSubmit={(values) => {
                    handleSubmit(values)
                }}
            >
            {({ values, setFieldValue, isSubmitting }) => (

                <Form>

                    {/* HEADER DEL MODAL */}

                    <Typography variant="h3" align="center" marginBottom={1}>
                        Agregar Orden
                    </Typography>

                    {/* BODY DEL MODAL */}

                    <Grid
                        container
                        spacing={2}
                        paddingBottom={2}
                        marginTop={2}
                    >

                        {/* SECCION PARA DATOS DE LA ORDEN */}

                        <Grid item xs={12}>

                            <Divider>Datos de la Orden</Divider>

                        </Grid>

                        {/* CLIENTE OPERACION */}

                        <Grid item md={3} xs={12}>

                            <MyAutoComplete
                                name="rel_cliente_operacion"
                                label="Cliente Operacion"
                                options={clientes}
                            />

                        </Grid>

                        {/* CLIENTE FACTURACION */}

                        <Grid item md={3} xs={12}>

                            <MyAutoComplete
                                name="rel_cliente_facturacion"
                                label="Cliente Facturacion"
                                options={clientes}
                            />

                        </Grid>

                        {/* OPERADOR */}

                        <Grid item md={3} xs={12}>

                            <MyAutoComplete
                                name="rel_operador"
                                label="Operador"
                                options={operadores}
                            />

                        </Grid>

                        {/* NOMBRE DEL TRACTOR */}

                        <Grid item md={3} xs={12}>

                            <MyAutoComplete
                                name="rel_tractor"
                                label="Tractor"
                                options={tractores}
                            />

                        </Grid>

                        {/* DATOS PARA LA CAJA */}

                        <Grid item xs={12}>

                            <Divider>Datos de la Caja</Divider>

                        </Grid>

                        {/* INPUT PARA CAJA */}

                        <Grid item md={2} xs={0}></Grid>

                        <Grid item md={4} xs={12}>

                            <TextInput
                                name="caja"
                                label="Caja"
                            />

                        </Grid>

                        {/* INPUT PARA PLACAS DE CAJA */}

                        <Grid item md={4} xs={12}>

                            <TextInput
                                name="caja_placas"
                                label="Placas"
                            />

                        </Grid>

                        <Grid item md={2} xs={0}></Grid>

                        {/* SECCION PARA DATOS DEL WAREHOUSE */}

                        <Grid item xs={12}>

                            <Divider>Datos de los Warehouse</Divider>

                        </Grid>

                        {/* WAREHOUSE DE ORIGEN */}

                        <Grid item md={6} xs={12}>

                            <MyAutoCompleteSublabels
                                options={warehouse}
                                label="Origen"
                                name="warehouse_origen"
                            />

                        </Grid>

                        {/* WAREHOUSE DE DESTINO */}

                        <Grid item md={6} xs={12}>

                            <MyAutoCompleteSublabels
                                name="warehouse_destino"
                                label="Destino"
                                options={warehouse}
                            />

                        </Grid>

                        {/* SECCION PARA DATOS DEL WAREHOUSE */}

                        <Grid item xs={12}>

                            <Divider>Fecha</Divider>

                        </Grid>

                        {/* FECHA DE CITA */}

                        <Grid item md={2} xs={0}></Grid>

                        <Grid item xs={4}>

                            <MyDateInput
                                name='fecha_cita'
                                label='Fecha de Cita'
                            />

                        </Grid>

                        {/* HORA DE CITA */}

                        <Grid item xs={4}>

                            <MyHourInput
                                name='hora_cita'
                                label='Hora de Cita'
                            />

                        </Grid>

                        <Grid item md={2} xs={0}></Grid>

                        {/* AREA PARA INGRESOS */}

                        <Grid item md={6} xs={12}>

                            <Accordion>

                                {/* HEADER DEL ACORDEON */}

                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                >
                                    <PaidIcon sx={{ marginRight: '10px'}}/> Ingresos
                                </AccordionSummary>

                                {/* CUERPO DEL ACORDEON */}

                                <AccordionDetails>

                                    <Grid container spacing={2}>

                                        {/* INPUT PARA CONCEPTO DE INGRESO */}

                                        <Grid item md={4} xs={12}>

                                            <TextInput
                                                name="conceptoIngreso"
                                                label="Concepto"
                                            />

                                        </Grid>

                                        {/* INPUT PARA MONTO DE INGRESO */}

                                        <Grid item md={3} xs={12}>

                                            <MyMoneyInput
                                                name="montoIngreso"
                                                label="Monto"
                                            />

                                        </Grid>

                                        {/* INPUT PARA TIPO MONEDA */}

                                        <Grid item md={3} xs={12}>

                                            <MySelect
                                                name="monedaIngreso"
                                                label="Moneda"
                                            >
                                                <MenuItem value='MXN'>MXN</MenuItem>
                                                <MenuItem value='USA'>USA</MenuItem>
                                            </MySelect>

                                        </Grid>

                                        {/* BOTON PARA AGREGAR INGRESO */}

                                        <Grid item md={2} xs={12}>

                                            <Button variant="contained" 
                                                sx={{
                                                        fontWeight:"bold",
                                                        backgroundColor: '#5DADE2',
                                                        height: '100%'
                                                }} 
                                                onClick={() => {

                                                    if(values.conceptoIngreso !== '' && values.montoIngreso !== '' && values.monedaIngreso !== ''){

                                                        agregarIngreso(values.conceptoIngreso, values.montoIngreso, values.monedaIngreso);

                                                        setFieldValue('conceptoIngreso', '');
                                                        setFieldValue('montoIngreso', '');
                                                        setFieldValue('monedaIngreso', '');

                                                    }                                                 
                                                    
                                                }}
                                            >
                                                <AddCircleIcon />
                                            </Button>

                                        </Grid>

                                        {/* TABLA INGRESOS */}

                                        <Grid item xs={12}>

                                            <TablaIngresos
                                                data={arrayIngresos}
                                                deleteData={eliminarIngreso}
                                            />

                                        </Grid>

                                    </Grid>

                                </AccordionDetails>

                            </Accordion>

                        </Grid>

                        {/* AREA PARA EGRESOS */}

                        <Grid item md={6} xs={12}>

                            <Accordion>

                                {/* HEADER DEL ACORDEON */}

                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                >
                                    <PaidIcon sx={{ marginRight: '10px'}}/> Egresos
                                </AccordionSummary>

                                {/* CUERPO DEL ACORDEON */}

                                <AccordionDetails>
                                    
                                    <Grid container spacing={2}>

                                        {/* INPUT PARA CONCEPTO DE INGRESO */}

                                        <Grid item md={4} xs={12}>

                                            <TextInput
                                                name="conceptoEgreso"
                                                label="Concepto"
                                            />

                                        </Grid>

                                        {/* INPUT PARA MONTO DE INGRESO */}

                                        <Grid item md={3} xs={12}>

                                            <MyMoneyInput
                                                name="montoEgreso"
                                                label="Monto"
                                            />

                                        </Grid>

                                        {/* INPUT PARA TIPO MONEDA */}

                                        <Grid item md={3} xs={12}>

                                            <MySelect
                                                name="monedaEgreso"
                                                label="Moneda"
                                            >
                                                <MenuItem value='MXN'>MXN</MenuItem>
                                                <MenuItem value='USA'>USA</MenuItem>
                                            </MySelect>

                                        </Grid>

                                        {/* BOTON PARA AGREGAR INGRESO */}

                                        <Grid item md={2} xs={12}>

                                            <Button variant="contained" 
                                                sx={{
                                                        fontWeight:"bold",
                                                        backgroundColor: '#5DADE2',
                                                        height: '100%'
                                                }} 
                                                onClick={() => {

                                                    if(values.conceptoEgreso !== '' && values.montoEgreso !== '' && values.monedaEgreso !== ''){

                                                        agregarEgreso(values.conceptoEgreso, values.montoEgreso, values.monedaEgreso);

                                                        setFieldValue('conceptoEgreso', '');
                                                        setFieldValue('montoEgreso', '');
                                                        setFieldValue('monedaEgreso', '');

                                                    }                                                 
                                                    
                                                }}
                                            >
                                                <AddCircleIcon />
                                            </Button>

                                        </Grid>

                                        <Grid item xs={12}>

                                            <TablaIngresos
                                                data={arrayEgresos}
                                                deleteData={eliminarEgreso}
                                            />

                                        </Grid>

                                        </Grid>

                                </AccordionDetails>

                            </Accordion>

                        </Grid>

                    </Grid>

                    {/* FOOTER DEL MODAL */}

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>

                        <Button 
                            type="submit" 
                            variant="contained" 
                            sx={{color: "white"}} 
                            color="success" 
                            startIcon={<SaveIcon />}
                            disabled={isSubmitting}
                        >
                            Guardar
                        </Button>

                    </Box>

                </Form>

            )}
            </Formik>

        </Box>

    )

}

export default CrearOrdenTransporte