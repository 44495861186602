import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useField } from 'formik';


const MyAutoCompleteSublabels = ({ options, label, onChange, ...props }) => {

    const [field, meta, helpers] = useField(props.name);

    const handleChange = (_, value) => {

        helpers.setValue(value ? value.value : null);

        if (onChange) {
            onChange(value);
        }

    };

    const handleBlur = () => {

        helpers.setTouched(true);

    };

    return (

        <Autocomplete
            sx={{ width: '100%' }}
            options={options}
            getOptionLabel={(option) => option.label}
            value={options.find((option) => option.value === field.value) || null}
            onChange={handleChange}
            onBlur={handleBlur}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    error={meta.touched && meta.error !== undefined}
                    helperText={meta.touched ? meta.error : ''}
                />
            )}
            renderOption={(props, option) => (
                <div {...props}>
                    <div style={{ marginRight: '10px' }}>{option.label}</div>
                    <div style={{ fontSize: 12, color: 'gray' }}>{option.sublabel}</div>
                </div>
            )}
        />

    )

}

export default MyAutoCompleteSublabels