import React, {useState, useEffect} from 'react';
import { api, fileDownload } from '../../../../config/axios';
import { Button, useTheme, Box, Grid, Typography, Card, CardHeader, CardActions, IconButton } from '@mui/material';
import { tokens } from "../../../../theme";
import { formatDate } from '../../../../config/functions';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import ModalSubirDocumento from './ModalSubirDocumento';

const TractorDocumentos = ({ idTractor = 0 }) => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // BUSCAR ARCHIVOS DEL CLIENTE
    // ===============================================

    const [archivos, setArchivos] = useState([]);

    // -----------------------------------------------

    const buscarDocumentos = async () => {

        const response = await api.get(`tractores/documentos/${idTractor}`);

        if(response.data !== "Sin Resultados"){

            setArchivos(response.data);

        }else{

            setArchivos([]);

        }
        
    }

    // ===============================================
    // ESTADOS PARA ABRIR EL MODAL
    // ===============================================

    const [modalOpen, setModalOpen] = useState(false);

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    // ===============================================
    // FUNCION PARA DESCARGAR EL ARCHIVO
    // ===============================================
    
    const handleDownload = (ruta) => {

        const file = ruta.split('/');
        const fileName = file[file.length -1];

        const data = {ruta: ruta};

        const endpoint = 'cliente/documentos/download';

        fileDownload.post(endpoint, data)
        .then(response => {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

        })
        .catch(error => {
            // Manejar errores de la solicitud
            console.error(error);
        });

    }

    // ===============================================
    // USE EFFECT PARA BUSCAR DOCUMENTOS
    // ===============================================

    useEffect(() => {

        // VALIDAMOS SI HAY DATOS SACAMOS EL ID DEL CLIENTE

        if(idTractor !== 0){

            buscarDocumentos();

        }

    },[idTractor])

    // ===============================================

    return (

        <Box>

            {/* BOTON PARA AGREGAR NUEVO DOCUMENTO */}

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>

                <Button variant="contained" 
                    sx={{
                            fontWeight:"bold",
                            backgroundColor:colors.redAccent[400]
                    }} 
                    startIcon={<AddCircleIcon />}
                    onClick={() => handleModalOpen()}
                >
                    Agregar Documento
                </Button>

            </Box>

            {/* SECCION PARA VER DOCUMENTOS */}

            <Grid container spacing={1} sx={{height: '50vh', overflowY: 'auto', marginTop: '10px'}}>

                {
                    archivos.map((file, index) => (

                        <Grid item md={4} xs={12} key={index}>

                            <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                                {/* HEADER DE LA TARJETA */}

                                <CardHeader
                                    title={
                                        <Typography variant="h4" align="center">
                                            {file.nombre_documento}
                                        </Typography>
                                    }
                                    subheader={
                                        <>
                                            <Typography variant="h6" align="center">
                                                Subido el {formatDate(file.timestamp)}
                                            </Typography>
                                        </>
                                    }
                                />

                                {/* VALIDAMOS ICONO DE LA TARJETA */}

                                {
                                    file.ruta.toLowerCase().endsWith('.pdf') ? (

                                    <IconButton >
                                        <PictureAsPdfIcon 
                                            sx={{ fontSize: 120 }}
                                        />
                                    </IconButton>

                                    )

                                    :

                                    file.ruta.toLowerCase().endsWith('.xlsx') ? (

                                    <IconButton >
                                        <InsertDriveFileIcon 
                                            sx={{ fontSize: 120 }}
                                        />
                                    </IconButton>

                                    )

                                    :

                                    <IconButton >
                                        <ImageIcon 
                                            sx={{ fontSize: 120 }}
                                        />
                                    </IconButton>

                                }

                                {/* FOOTER DE LA TARJETA */}

                                <CardActions disableSpacing>
                                    <IconButton onClick={() => handleDownload(file.ruta)}> 
                                        <DownloadIcon/>
                                    </IconButton>
                                </CardActions>
                            </Card>

                        </Grid>

                    ))
                }

            </Grid>

            {/* MODAL PARA SUBIR DOCUMENTOS */}

            <ModalSubirDocumento
                open={modalOpen}
                onClose={handleModalClose}
                refresh={buscarDocumentos}
                idTractor={idTractor}
            />

        </Box>

    )

}

export default TractorDocumentos