import React from 'react';
import { Document, Page, View, Text, Image, StyleSheet } from "@react-pdf/renderer";
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';

// IMAGENES
import logok9 from '../../../../assets/img/plantillak9/k9LogoPng.png';
import imgFirma from '../../../../assets/img/plantillak9/firma.png';

// ===============================================
// ESTILOS PARA EL PDF
// ===============================================

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    image: {
        width: '200px',
        height: '100px'
    },
    text_header: {
        marginTop: '40px',
        fontSize: '13px',
        textAlign: 'center'
    },
    text: {
        fontSize: '13px',
        padding: '0px 30px 0px 20px'
    },
    boldText: {
        fontWeight: 'bold',
        fontSize: '15px',
    },
    row: {
        flexDirection: 'row',
        width: '100%',
        margin: '15px 0px 15px 30px'
    },
    finalSection: {
        textAlign: 'center',
        marginTop: '20px'
    }
});

// ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

const CartaRetiroK9 = ({ stopData }) => {

    // ===============================================
    // FUNCION PARA GENERAR FECHA
    // ===============================================

    const obtenerFechaActual = () => {

        const fecha = new Date(); // El mes se indica como 11 ya que los meses en JavaScript van de 0 a 11
        const formatoFecha = 'd \'de\' MMMM \'del\' yyyy';

        return format(fecha, formatoFecha, { locale: esLocale });

    }

    // ===============================================

    return (

        <Document>

            <Page size="A4" style={styles.page}>

                {/* SECCION DE IMAGEN */}

                <View style={styles.section}>
                    <Image src={logok9} style={styles.image}/>
                </View>

                {/* FECHA ACTUAL */}

                <View style={styles.section}>
                    <Text style={styles.text_header}>Nuevo Laredo, Tamaulipas, {obtenerFechaActual()}</Text>
                </View>

                {/* BODY */}

                <View style={styles.section}>
                    <Text style={styles.text}>A quien corresponda:</Text>
                </View>

                <View style={styles.section}>

                    <Text style={styles.text}>
                        Por medio de la presente solicitamos su autorización para retirar de sus instalaciones el semirremolque cuyos datos a continuación se indican:
                    </Text>

                </View>

                {/* OPERADOR */}

                <View style={styles.row}>

                    <Text style={styles.text}>
                        <Text style={styles.boldText}>OPERADOR:</Text> {stopData.operador}
                    </Text>

                </View>

                {/* TRACTOR */}

                <View style={styles.row}>

                    <Text style={styles.text}>
                        <Text style={styles.boldText}>UNIDAD</Text>: {stopData.tractor}
                    </Text>

                </View>

                {/* CAJA */}

                <View style={styles.row}>

                    <Text style={styles.text}>
                        <Text style={styles.boldText}>CAJA</Text>: {stopData.caja}
                    </Text>

                </View>

                {/* DESTINO */}

                <View style={styles.row}>

                    <Text style={styles.text}>
                        <Text style={styles.boldText}>DESTINO</Text>:  {stopData.destino}
                    </Text>

                </View>

                {/* FOOTER */}

                <View style={styles.section}>

                    <Text style={styles.text}>
                        La Sra Ma del Carmen se Hace responsable Del Traslado Y Resguardo Del Remolque a Liberar Sin Más Por El Momento, Aprovecho La Ocasión Para Enviarle Un Cordial Saludo.
                    </Text>

                </View>

                {/* SECCION DE LA FIRMA */}

                <View style={styles.finalSection}>

                    <Image src={imgFirma} style={styles.image} />

                </View>

            </Page>

        </Document>

    )

}

export default CartaRetiroK9