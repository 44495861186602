import React, {useEffect, useState} from 'react'
import { NavLink } from "react-router-dom";
import { Grid, Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from '../../components/Header'
import { modulos, iconos } from '../../data/modulosk9';
import { marcarURL } from '../../config/functions';
import Cookies from 'js-cookie';

const Catalogos = () => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [catalogoMenu, setCatalogoMenu] = useState([]);

    useEffect(() => {

      marcarURL('catalogos')

      const menu = JSON.parse(Cookies.get('menu'));

      const menuCatalogo = modulos.filter((obj) => obj.type === 'menu' && obj.seccion === 'Catalogo');

      // const menuFiltrado = menuCatalogo.filter()

      // console.log(menuCatalogo)

      setCatalogoMenu(menuCatalogo)

    },[])

    return (
        <Box m="20px">

          <Header title="Catalogos" subtitle="" />

          <Grid container spacing={2}>

            {catalogoMenu.map((modulo, index) => {

              if(modulo.seccion === 'Catalogo'){

                const IconoDinamico = iconos[modulo.icono];
    
                return (
  
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
  
                    <NavLink
                      to={`/${modulo.url}`}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                      activestyle={{ fontWeight: 'bold' }}
                    >
  
                      <Box
                        sx={{
                          border: 1,
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                          "&:hover": {
                            color: colors.redAccent[500],
                          },
                        }}
                      >
  
                        {IconoDinamico && <IconoDinamico sx={{ fontSize: 48 }} />}
  
                        <Typography variant="body1" sx={{ mt: 1 }}>
                          {modulo.modulo}
                        </Typography>
  
                      </Box>
  
                    </NavLink>
  
                  </Grid>
  
                );

              }

            })}

          </Grid>

        </Box>
    );

}

export default Catalogos