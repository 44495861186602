import React, { useEffect, useState } from 'react';
import { Button, Typography, Box, Container, Grid  } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {api} from '../../../../config/axios';
import Swal from 'sweetalert2';
import { styled } from '@mui/system';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';

// COMPONENTES
import TextInput from '../../../../components/inputs/TextInput';
import MyPhoneInput from '../../../../components/inputs/MyPhoneInput';

const EditarCarrier = ({ data }) => {

    // ===============================================
    // ESTADO PARA DATOS DEL FORMULARIO
    // ===============================================

    const [formValues, setFormValues] = useState({

        carrier_name: '', 
        contacto: '', 
        telefono: '',

    });

    // ===============================================
    // BODY DEL MODAL
    // ===============================================

    const ScrollableContainer = styled(Container)({
        maxHeight: '60vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });

    // ===============================================
    // ACTUALIZAR VALORES DEL FORMULARIO
    // ===============================================

    useEffect(() => {

        if(data !== ''){

            setFormValues(data);

        }

    },[data]);

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = (values) => {

        const url = 'carrier/update';

        api.put(url, values)
        .then((response) => {

            if(response.data.success === true){

                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                })

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    // ===============================================

    return (

        <Formik
                initialValues={formValues}
                enableReinitialize={true}
                validationSchema={Yup.object({
                    carrier_name: Yup.string().required('Requerido'),
                    contacto: Yup.string().required('Requerido'),
                    telefono: Yup.string().required('Requerido'),
                })}
                onSubmit={(values) => {
                    handleSubmit(values)
                }}
        >

            <Form>

                {/* HEADER DEL MODAL */}

                <Typography variant="h3" align="center" marginBottom={1}>
                    Editar Carrier
                </Typography>

                {/* BODY DEL MODAL */}

                <ScrollableContainer>

                    <Grid 
                        container
                        spacing={2}
                        paddingBottom={2}
                        marginTop={2}
                    >

                        {/* NOMBRE DE CARRIER */}

                        <Grid item xs={12}>

                            <TextInput
                                name="carrier_name"
                                label="Carrier"
                            />

                        </Grid>

                        {/* CONTACTO DEL CARRIER */}

                        <Grid item xs={12}>

                            <TextInput
                                name="contacto"
                                label="Contacto"
                            />

                        </Grid>

                        {/* TELEFONO DEL CARRIER */}

                        <Grid item xs={12}>

                            <MyPhoneInput
                                name="telefono"
                                label="Telefono"
                            />

                        </Grid>

                    </Grid>

                </ScrollableContainer>

                {/* FOOTER DEL MODAL */}

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>

                    <Button 
                        type="submit" 
                        variant="contained" 
                        sx={{color: "white"}} 
                        color="success" 
                        startIcon={<SaveIcon />}
                    >
                        Guardar
                    </Button>

                </Box>

            </Form>

        </Formik>

    )

}

export default EditarCarrier