import React, { useEffect, useState } from 'react';
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../../theme";
import {api, fileDownload} from '../../../../config/axios';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DownloadIcon from '@mui/icons-material/Download';

//COMPONENTES
import MiTabla from '../../../../components/plantilla/MiTabla';
import ModalCrearPlacas from './ModalCrearPlacas';

const TractorPlacas = ({ idTractor = 0 }) => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // FUNCION PARA BUSCAR PLACAS DE TRACTORES
    // ===============================================

    const [data, setData] = useState([]);

    // -----------------------------------------------

    const buscarPlacas = async () => {

        const response = await api.get(`tractores/placas/${idTractor}`);

        if(response.data !== "Sin Resultados"){

            setData(response.data);

        }else{

            setData([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        {
            field: "num_placa",
            headerName: "# Placa",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "subido_por",
            headerName: "Subido Por",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "fecha_expiracion",
            headerName: "Expiracion",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => params.value.split('T')[0]
        },
        {
            field: "vigente",
            headerName: "Estado",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => 
                params.value === 1 
                ?
                <Button variant="contained" color="success" sx={{color: "white"}}><b>Vigente</b></Button>
                :
                <Button variant="contained" color="error"><b>Expirado</b></Button>
        },
        {
            field: "ruta",
            headerName: "Descargar",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => 
                <Button 
                    variant="contained" 
                    onClick={() => handleDownload(params.value)}
                    sx={{
                        color: "white", backgroundColor: '#04b6ff'
                    }}
                >
                    <DownloadIcon/>
                </Button>
        },

    ];

    // ===============================================
    // FUNCION PARA DESCARGAR EL ARCHIVO
    // ===============================================

    const handleDownload = (ruta) => {

        const file = ruta.split('/');
        const fileName = file[file.length -1];

        const data = {ruta: ruta};

        const endpoint = 'tractores/placas/download';

        fileDownload.post(endpoint, data)
        .then(response => {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

        })
        .catch(error => {
            // Manejar errores de la solicitud
            console.error(error);
        });

    }

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR MODALES
    // ===============================================

    const [modal, setModal] = useState(false)

    // -----------------------------------------------

    const handleOpenModal = () => {

        setModal(true);

    };

    // -----------------------------------------------
    
    const handleCloseModal = () => {

        setModal(false);

    };

    // ===============================================
    // USE EFFECT PARA LLAMAR A LAS FUNCIONES
    // ===============================================

    useEffect(() => {

        if(idTractor !== 0){

            buscarPlacas();

        }

    },[idTractor]);

    // ===============================================

    return (

        <Box>

            {/* BOTON PARA ABRIR MODAL */}

            <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.redAccent[400]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={() => handleOpenModal()}
            >
                Agregar Placas
            </Button>

            {/* TABLA */}

            <MiTabla
                data={data}
                columns={columns}
                //rowClick={handleRowClick}
            />

            {/* MODAL PARA CREAR PLACAS */}

            <ModalCrearPlacas
                open={modal}
                onClose={handleCloseModal}
                refresh={buscarPlacas}
                idTractor={idTractor}
            />

        </Box>

    )

}

export default TractorPlacas