import React, { useState } from 'react';
import { Button, Modal, Paper, Typography, Box, Grid, MenuItem, Alert } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';
import useMediaQuery from "@mui/material/useMediaQuery";
import { sendFormData } from '../../../../config/axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';

// COMPONENTES
import MyInputFile from '../../../../components/inputs/MyInputFile';
import TextInput from '../../../../components/inputs/TextInput';
import MySelect from '../../../../components/inputs/MySelect';
import MyDateInput from '../../../../components/inputs/MyDateInput';
import MySwitch from '../../../../components/inputs/MySwitch';

const ModalSubirDocumento = ({ open, onClose, refresh, idOperador }) => {

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // VALIDAR SI SE ESTA VIENDO DESDE UN DISPOSITVO MOVIL
    // ===============================================

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // ===============================================
    // ESTADO PARA CONTROLAR ALERTA
    // ===============================================

    const [showWarning, setShowWarning] = useState(false);

    // ===============================================
    // SUBMIT DEL MODAL MULTIPLES ARCHIVOS
    // ===============================================

    const handleSubmit = async (values) => {

        const url = 'operadores/documentos/crear';

        // VALIDAMOS SI LLEVA DECHA DE EXPIRACION

        if(values.expira === '0'){

            values.fecha_expiracion = null

        }else{

            if(values.fecha_expiracion === ''){

                setShowWarning(true);

                return;

            }else{

                setShowWarning(false);

                values.fecha_expiracion = values.fecha_expiracion.split('T')[0];

            }

        }

        // HACEMOS EL SUBMIT DEL FORMULARIO

        try{

            values.rel_operador = idOperador;
            values.rel_user = Cookies.get('idUser');

            const result = await sendFormData(url, values);

            if(result.success === true){

                onClose();

                Swal.fire({
                    icon: 'success',
                    title: result.message,
                })

                refresh();

            }

        }catch(error){

            console.error("Upload failed:", error);

        }

    };

    // ===============================================

    return (

        <Modal
            open={open}
            onClose={onClose}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >

            <Formik
                initialValues={{ 
                    tipo_documento: '',
                    descripcion: '',
                    expira: '0',
                    fecha_expiracion: '',
                    ruta: '' 
                }}
                validationSchema={Yup.object().shape({
                    tipo_documento: Yup.string().required('Requerido'),
                    descripcion: Yup.string().required('Requerido'),
                    expira: Yup.string().required('Requerido'),
                    ruta: Yup.mixed()
                    .test('fileType', 'Formato de archivo no válido', (value) => {
                        return value && ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'].includes(value.type);
                    })
                    .test('fileSize', 'El archivo es demasiado grande', (value) => {
                        return value && value.size <= 10485760; // 10MB
                    })
                })}
                onSubmit={handleSubmit}
            >
            {({ values }) => (

                <Paper
                    sx={{ 
                        width: isNonMobile ? `30%` : '90%', 
                        p: 3, 
                        bgcolor: colors.primary[1000] 
                    }}
                >

                    {/* HEADER DEL MODAL */}

                    <Typography variant="h4" align="center" mb={2}>
                        Subir Documento
                    </Typography>

                    {/* CUERPO DEL MODAL */}

                    <Form>

                        <Grid container spacing={2}>

                            {/* SELECCIONAR TIPO DE DOCUMENTO */}

                            <Grid item xs={12}>

                                <MySelect
                                    name="tipo_documento"
                                    label="Seleccione Tipo de Documento"
                                >
                                    <MenuItem value={'Apto Medico'}>Apto Medico</MenuItem>
                                    <MenuItem value={'B1'}>Permiso B1</MenuItem>
                                    <MenuItem value={'Licencia'}>Licencia</MenuItem>
                                </MySelect>

                            </Grid>

                            {/* NOMBRE DEL DOCUMENTO */}

                            <Grid item xs={8}>

                                <TextInput
                                    name="descripcion"
                                    label="Descripcion"
                                />

                            </Grid>

                            {/* EXPIRA ? */}

                            <Grid item xs={4}>

                                <MySwitch
                                    name="expira"
                                    label="¿ Expira ?"
                                />

                            </Grid>

                            {/* FECHA EXPIRACION */}

                            {
                                values.expira === 1 && (

                                    <Grid item xs={12}>

                                        <MyDateInput
                                            name="fecha_expiracion"
                                            label="Fecha Expiracion"
                                        />

                                    </Grid>

                                )
                            }

                            {/* ALERTA PARA VALIDAR FECHA DE EXPIRACION */}

                            {
                                showWarning && (

                                    <Grid item xs={12}>

                                        <Alert severity="warning">Seleccione una fecha para continuar !</Alert>

                                    </Grid>

                                )
                            }

                            {/* ARCHIVO DEL DOCUMENTO */}

                            <Grid item xs={12}>

                                <MyInputFile
                                    name="ruta"
                                    label="Subir Archivo"
                                    accept=".jpg, .jpeg, .png, .pdf, .xlsx"
                                />

                            </Grid>

                        </Grid>

                        {/* FOOTER DEL MODAL */}

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>

                            <Button variant="contained" color="primary" onClick={onClose}>
                                Cancelar
                            </Button>

                            <Button 
                                type="submit" 
                                variant="contained" 
                                sx={{color: "white"}} 
                                color="success" 
                                startIcon={<SaveIcon />} 
                            >
                                Guardar
                            </Button>

                        </Box>

                    </Form>

                </Paper>

            )}
            </Formik>

        </Modal>

    )

}

export default ModalSubirDocumento