import React from 'react';
import { useField } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const MyDateInput = ({ label, name, format, ...restProps }) => {
  const [field, meta, helpers] = useField(name);

  const handleChange = (value) => {
    const formattedDate = value ? value.format(format) : '';
    helpers.setValue(formattedDate);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        {...field}
        {...restProps}
        sx={{ width: '100%' }}
        label={label}
        value={field.value ? dayjs(field.value, format) : null}
        onChange={handleChange}
        onBlur={handleBlur}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
      />
    </LocalizationProvider>
  );
};

export default MyDateInput;
