import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, Button } from '@mui/material';
import { api } from '../../../config/axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// ICONO
import SearchIcon from '@mui/icons-material/Search';
import MyAutoCompleteSublabels from '../../../components/inputs/MyAutoCompleteSublabels';

const Filtro = ({ idOrden, select }) => {

    // ===============================================
    // FUNCION PARA BUSCAR MOVIMIENTOS
    // ===============================================

    const [stops, setStops] = useState([]);

    const buscarStops = async () => {

        const response = await api.get(`transporte/ordenes/stops/select/${idOrden}`);

        if(response.data !== "Sin Resultados"){

            setStops(response.data);

        }

    }

    // ===============================================
    // USE EFFECT PARA LLAMAR A LAS FUNCIONES
    // ===============================================

    useEffect(() => {

        buscarStops();

    },[]);

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = (values) => {

        const id = values.idStop;

        select(id)

    }

    // ===============================================

    return (

        <Formik
            initialValues={{
                idStop: '',
            }}
            validationSchema={Yup.object({
                idStop: Yup.string().required('Requerido'),
            })}
            onSubmit={(values) => {
                handleSubmit(values)
            }}
        >

            <Form>

                {/* BODY DEL MODAL */}

                <Grid 
                    container
                    spacing={2}
                    paddingBottom={2}
                    marginTop={2}
                >
                    
                    {/* AREA PARA SELECCIONAR MOVIMIENTO */}

                    <Grid item xs={11}>

                        <MyAutoCompleteSublabels
                            name="idStop"
                            label="Stop"
                            options={stops}
                        />

                    </Grid>

                    <Grid item xs={1} sx={{display: 'flex', justifyItems: 'center'}}>

                        <Button 
                            type="submit" 
                            variant="contained" 
                            sx={{
                                color: "white",
                                backgroundColor: '#03afff'
                            }} 
                        >
                            <SearchIcon/>
                        </Button>

                    </Grid>

                </Grid>

            </Form>

        </Formik>

    )

}

export default Filtro