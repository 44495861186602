import React from 'react';
import { Box, Grid } from "@mui/material";
import { useLocation } from 'react-router-dom';
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';

// COMPONENTES
import EditarCarrier from '../carrier_datos/EditarCarrier';
import CarrierDocumentos from '../carrier_documentos/CarrierDocumentos';

const CarrierVista = () => {

    // ===============================================
    // OBTENER INFORMACION DEL CURSO
    // ===============================================

    const location = useLocation();
    const { data } = location.state;

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================

    return (

        <Box m="20px">

            <Grid container>

                {/* SECCION PARA EL FORMULARIO DEL CARRIER */}

                <Grid
                    item md={5} 
                    xs={12}
                    sx={{
                        padding: '20px',
                    }}
                >

                    <EditarCarrier 
                        data={data} 
                    />

                </Grid>

                {/* SECCION PARA DOCUMENTOS DEL CARRIER */}

                <Grid
                    item 
                    md={7} 
                    xs={12}
                    sx={{
                        backgroundColor: colors.grey[900],
                        padding: '20px',
                        borderRadius: '10px',
                        height: '85vh'
                    }}
                >

                    <CarrierDocumentos
                        data={data}
                    />

                </Grid>

            </Grid>

        </Box>

    )

}

export default CarrierVista