import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal, Paper, Typography, Box, Container, Grid } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {api} from '../../../../config/axios';
import Swal from 'sweetalert2';
import { styled } from '@mui/system'
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';
import Cookies from 'js-cookie';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';

// COMPONENTES
import MyAutoCompleteSublabels from '../../../../components/inputs/MyAutoCompleteSublabels';
import TextAreaInput from '../../../../components/inputs/TextAreaInput';

const ModalCrearIncidencias = ({open, onClose, refresh, idOrden, idOperador}) => {

    // ===============================================
    // BODY DEL MODAL
    // ===============================================

    const ScrollableContainer = styled(Container)({
        maxHeight: '60vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });

    // ===============================================
    // VALIDAR SI SE VE DESDE UN MOVIL
    // ===============================================

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // FUNCION PARA BUSCAR MOVIMIENTOS
    // ===============================================

    const [stops, setStops] = useState([]);

    const buscarStops = async () => {

        const response = await api.get(`transporte/ordenes/stops/select/${idOrden}`);

        if(response.data !== "Sin Resultados"){

            setStops(response.data);

        }

    }

    // ===============================================
    // USE MEMO PARA BUSCAR MOVIMIENTOS POR VIAJE
    // ===============================================

    const buscarStopsMemo = useMemo(() => {

        return {
            buscarStops
        }

    });

    // ===============================================
    // USE EFFECT PARA LLAMAR FUNCIONES
    // ===============================================

    useEffect(() => {

        // LLAMAMOS LAS FUNCIONES DESDE USEMEMO

        buscarStopsMemo.buscarStops();

    },[]);

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = (values) => {

        const url = 'operadores/incidencias/crear';

        values.rel_operador = idOperador;
        values.rel_user = Cookies.get('idUser');
        values.rel_orden = idOrden;

        api.post(url, values)
        .then((response) => {

            if(response.data.success === true){

                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                });

                refresh();

                onClose('crear');

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    // ===============================================

    return (

        <Modal
            open={open}
            onClose={() => onClose('crear')}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >

            <Formik
                initialValues={{
                    rel_stop: '',
                    comentario: '',
                }}
                validationSchema={Yup.object({
                    rel_stop: Yup.string().required('Requerido'),
                    comentario: Yup.string().required('Requerido'),
                })}
                onSubmit={(values) => {
                    handleSubmit(values)
                }}
            >
            {({ isSubmitting }) => (

                <Paper
                    sx={{ 
                        width: isNonMobile ? `50%` : '90%', 
                        p: 3, 
                        bgcolor: colors.primary[1000] 
                    }}
                >

                    <Form>

                        {/* HEADER DEL MODAL */}

                        <Typography variant="h3" align="center" marginBottom={1}>
                            Agregar Incidencia
                        </Typography>

                        {/* BODY DEL MODAL */}

                        <ScrollableContainer>

                            <Grid 
                                container
                                spacing={2}
                                paddingBottom={2}
                                marginTop={2}
                            >
                                
                                {/* STOPS DEL VIAJE */}

                                <Grid item xs={12}>

                                    <MyAutoCompleteSublabels
                                        name="rel_stop"
                                        label="Stop"
                                        options={stops}
                                    />

                                </Grid>

                                {/* COMENTARIO */}

                                <Grid item xs={12}>

                                    <TextAreaInput
                                        name="comentario"
                                        label="Comentario"
                                    />

                                </Grid>

                            </Grid>

                        </ScrollableContainer>

                        {/* FOOTER DEL MODAL */}

                        <Box 
                            sx={{ 
                                display: 'flex', 
                                justifyContent: 'space-between',
                                mt: 2 
                            }}
                        >

                            <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={() => onClose('crear')}
                            >
                                Cancelar
                            </Button>

                            <Button 
                                type="submit" 
                                variant="contained" 
                                sx={{color: "white"}} 
                                color="success" 
                                startIcon={<SaveIcon />}
                                disabled={isSubmitting}
                            >
                                Guardar
                            </Button>

                        </Box>

                    </Form>

                </Paper>

            )}
            </Formik>

        </Modal>

    )

}

export default ModalCrearIncidencias