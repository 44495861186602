import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal, Paper, Typography, Box, Container, Grid, MenuItem, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {api} from '../../../../config/axios';
import Swal from 'sweetalert2';
import { styled } from '@mui/system'
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// DATOS
import { estadosMx, estadosUs } from '../../../../data/datos';

// COMPONENTES
import TextInput from '../../../../components/inputs/TextInput';
import MyPhoneInput from '../../../../components/inputs/MyPhoneInput';
import MyAutoComplete from '../../../../components/inputs/MyAutoComplete';
import MySelect from '../../../../components/inputs/MySelect';
import MyHourInput from '../../../../components/inputs/MyHourInput';

const ModalEditarWarehouse = ({open, onClose, refresh, data = 0}) => {

    // ===============================================
    // BODY DEL MODAL
    // ===============================================

    const ScrollableContainer = styled(Container)({
        maxHeight: '60vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });

    // ===============================================
    // VALIDAR SI SE VE DESDE UN MOVIL
    // ===============================================

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // FUNCION PARA BUSCAR CLIENTES
    // ===============================================

    const [clientes, setClientes] = useState([]);

    const buscarClientes = async () => {

        const response = await api.get('clientes/select');

        if(response.data !== "Sin Resultados"){

            setClientes(response.data);

        }else{

            setClientes([]);

        }

    }

    // ===============================================
    // ARRAY PARA HORARIOS DEL DIA DE LA SEMANA
    // ===============================================

    const daysOfWeek = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];

    // ===============================================
    // USE MEMO PARA BUSCAR CLIENTES
    // ===============================================

    const memorizedClientes = useMemo(() => buscarClientes, []);

    // ===============================================
    // ESTADO PARA DATOS DEL FORMULARIO
    // ===============================================

    const [formValues, setFormValues] = useState({

        warehouse_name: '', 
        rel_cliente: '', 
        direccion: '',
        ciudad: '',
        estado: '',
        pais: '',
        contacto: '',
        telefono: ''

    });

    // ===============================================
    // USE EFFECT PARA LLAMAR FUNCIONES
    // ===============================================

    useEffect(() => {

        memorizedClientes();

        if(data !== 0){

            setFormValues(data);

        }

    },[data])

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = (values) => {

        const url = 'warehouse/update';

        // CONVERTIMOS A STRING EL JSON

        if(values.horario !== null){

            values.horario = JSON.stringify(values.horario);

        } else {

            values.horario = null;

        }

        // MANDAMOS EL PUT

        api.put(url, values)
        .then((response) => {

            if(response.data.success === true){

                onClose('editar');

                refresh();

                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                })

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    // ===============================================

    return (

        <Modal
            open={open}
            onClose={() => onClose('editar')}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >

            <Formik
                initialValues={formValues}
                enableReinitialize={true}
                validationSchema={Yup.object({
                    warehouse_name: Yup.string().required('Requerido'),
                    rel_cliente: Yup.string().required('Requerido'),
                    direccion: Yup.string().required('Requerido'),
                    ciudad: Yup.string().required('Requerido'),
                    estado: Yup.string().required('Requerido'),
                    pais: Yup.string().required('Requerido'),
                })}
                onSubmit={(values) => {
                    handleSubmit(values)
                }}
            >
            {({ values, isSubmitting }) => (

                <Paper
                    sx={{ 
                        width: isNonMobile ? `50%` : '90%', 
                        p: 3, 
                        bgcolor: colors.primary[1000] 
                    }}
                >

                    <Form>

                        {/* HEADER DEL MODAL */}

                        <Typography variant="h3" align="center" marginBottom={1}>
                            Editar Warehouse
                        </Typography>

                        {/* BODY DEL MODAL */}

                        <ScrollableContainer>

                            <Grid 
                                container
                                spacing={2}
                                paddingBottom={2}
                                marginTop={2}
                            >
                                
                                {/* CLIENTE */}

                                <Grid item xs={6}>

                                    <MyAutoComplete
                                        name="rel_cliente"
                                        label="Cliente"
                                        options={clientes}
                                    />

                                </Grid>

                                {/* NOMBRE DEL WAREHOUSE */}

                                <Grid item xs={6}>

                                    <TextInput
                                        name="warehouse_name"
                                        label="Warehouse"
                                    />

                                </Grid>

                                {/* DIRECCION DEL WAREHOUSE */}

                                <Grid item xs={12}>

                                    <TextInput
                                        name="direccion"
                                        label="Direccion"
                                    />

                                </Grid>

                                {/* PAIS DEL WAREHOUSE */}

                                <Grid item xs={4}>

                                    <MySelect
                                        name="pais"
                                        label="Pais"
                                    >
                                        <MenuItem value={'Estados Unidos'}>Estados Unidos</MenuItem>
                                        <MenuItem value={'Mexico'}>Mexico</MenuItem>
                                    </MySelect>

                                </Grid>

                                {/* ESTADO DEL WAREHOUSE */}

                                <Grid item xs={4}>

                                    <MyAutoComplete
                                        name="estado"
                                        label="Estado"
                                        options={
                                            values.pais === 'Mexico'
                                            ?
                                            estadosMx
                                            :
                                            values.pais === 'Estados Unidos'
                                            ?
                                            estadosUs
                                            :
                                            []
                                        }
                                    />

                                </Grid>

                                {/* CIUDAD DEL WAREHOUSE */}

                                <Grid item xs={4}>

                                    <TextInput
                                        name="ciudad"
                                        label="Ciudad"
                                    />

                                </Grid>

                                {/* CONTACTO DEL WAREHOUSE */}

                                <Grid item xs={6}>

                                    <TextInput
                                        name="contacto"
                                        label="Contacto"
                                    />

                                </Grid>

                                {/* TELEFONO DEL CARRIER */}

                                <Grid item xs={6}>

                                    <MyPhoneInput
                                        name="telefono"
                                        label="Telefono"
                                    />

                                </Grid>

                                {/* SECCION PARA LOS HORARIOS */}

                                <Grid item xs={12}>

                                    <Accordion>
                                        
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            Horarios
                                        </AccordionSummary>

                                        <AccordionDetails>

                                            <Grid container spacing={2}>

                                            {
                                                daysOfWeek.map((day) => (

                                                    <>

                                                    {/* DIA DE LA SEMANA */}

                                                    <Grid item xs={12}>

                                                        <Typography 
                                                            variant="h4" 
                                                            align="center" 
                                                            marginBottom={1}
                                                        >
                                                            {day}
                                                        </Typography>

                                                    </Grid>

                                                    {/* HORA DE ENTRADA */}

                                                    <Grid item xs={6}>

                                                        <MyHourInput
                                                            label='Abre'
                                                            name={`horario.${day}.abre`}
                                                        />

                                                    </Grid>

                                                    {/* HORA DE SALIDA */}

                                                    <Grid item xs={6}>

                                                        <MyHourInput
                                                            label='Cierra'
                                                            name={`horario.${day}.cierra`}
                                                        />

                                                    </Grid>
                                                    
                                                    </>

                                                ))
                                            }

                                            </Grid>
                                            
                                        </AccordionDetails>

                                    </Accordion>

                                </Grid>

                            </Grid>

                        </ScrollableContainer>

                        {/* FOOTER DEL MODAL */}

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>

                            <Button variant="contained" color="primary" onClick={() => onClose('editar')}>
                                Cancelar
                            </Button>

                            <Button 
                                type="submit" 
                                variant="contained" 
                                sx={{color: "white"}} 
                                color="success" 
                                startIcon={<SaveIcon />}
                                disabled={isSubmitting}
                            >
                                Guardar
                            </Button>

                        </Box>

                    </Form>

                </Paper>

            )}
            </Formik>

        </Modal>

    )

}

export default ModalEditarWarehouse