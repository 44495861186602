import React, {useState, useEffect} from 'react';
import { api, fileDownload } from '../../../../config/axios';
import { Button, useTheme, Box, Grid, Typography, Card, CardHeader, CardActions, IconButton, Chip, TextField } from '@mui/material';
import { tokens } from "../../../../theme";
import { formatDate } from '../../../../config/functions';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DownloadIcon from '@mui/icons-material/Download';
import DescriptionIcon from '@mui/icons-material/Description';
import SearchIcon from '@mui/icons-material/Search';

// COMPONENTES
import ModalSubirDocumento from './ModalSubirDocumento';

const OperadorDocumentos = ({ idOperador = 0 }) => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // BUSCAR ARCHIVOS DEL CLIENTE
    // ===============================================

    const [archivos, setArchivos] = useState([]);

    const buscarDocumentos = async () => {

        const response = await api.get(`operadores/documentos/${idOperador}`);

        if(response.data !== "Sin Resultados"){

            setArchivos(response.data);

            setDocumentos(response.data);

        }else{

            setArchivos([]);

        }
        
    }

    // ===============================================
    // ESTADOS PARA ABRIR EL MODAL
    // ===============================================

    const [modalOpen, setModalOpen] = useState(false);

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    // ===============================================
    // FUNCION PARA DESCARGAR EL ARCHIVO 
    // ===============================================

    const handleDownload = (ruta) => {

        const file = ruta.split('/');
        const fileName = file[file.length -1];

        const data = {ruta: ruta};

        const endpoint = 'cliente/documentos/download';

        fileDownload.post(endpoint, data)
        .then(response => {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

        })
        .catch(error => {
            // Manejar errores de la solicitud
            console.error(error);
        });

    }

    // ===============================================
    // FUNCION PARA FILTRAR DOCUMENTOS
    // ===============================================

    const [documentos, setDocumentos] = useState([]);

    const filtrarDocs = (value) => {

        if(archivos.length > 0){

            const filtro = archivos.filter(obj => obj.tipo_documento.toLowerCase().includes(value) || obj.tipo_documento.toLowerCase() === value.toLowerCase());

            setDocumentos(filtro)

        }

    }

    // ===============================================
    // USE EFFECT PARA BUSCAR DOCUMENTOS
    // ===============================================

    useEffect(() => {

        // VALIDAMOS SI HAY DATOS SACAMOS EL ID DEL CLIENTE

        if(idOperador !== 0){

            buscarDocumentos();

        }

    },[idOperador])

    // ===============================================

    return (

        <Box>

            {/* BOTON PARA AGREGAR NUEVO DOCUMENTO */}

            <Box display={'flex'} justifyContent={'space-between'}>

                <Button variant="contained" 
                    sx={{
                            fontWeight:"bold",
                            backgroundColor:colors.redAccent[400]
                    }} 
                    startIcon={<AddCircleIcon />}
                    onClick={() => handleModalOpen()}
                >
                    Agregar Documento
                </Button>

                {/* INPUT PARA FILTRAR */}

                <Box sx={{ display: 'flex', alignItems: 'flex-end', marginRight: '20px' }}>
                    <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <TextField 
                        id="input-with-sx" 
                        label="Buscar Documento" 
                        variant="standard" 
                        onChange={(event) => filtrarDocs(event.target.value)}
                    />
                </Box>

            </Box>

            {/* SECCION PARA VER DOCUMENTOS */}

            <Grid container spacing={1} sx={{height: '50vh', overflowY: 'auto', marginTop: '10px'}}>

                {
                    documentos.map((file, index) => (

                        <Grid item md={4} xs={12} key={index}>

                            <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                                {/* HEADER DE LA TARJETA */}

                                <CardHeader
                                    title={
                                        <Typography variant="h4" align="center">
                                            {file.tipo_documento}
                                        </Typography>
                                    }
                                    subheader={
                                        <>
                                            <Typography variant="h6" align="center">
                                                Subido el {formatDate(file.timestamp)}
                                            </Typography>

                                            {
                                                file.expira === 1 && (

                                                    file.estado === 1 
                                                    ?
                                                    (
                                                        <Typography variant="h6" align="center" marginTop={2}>
                                                            <Chip label="Vigente" sx={{ fontWeight: 'bold', backgroundColor: 'green'}} />
                                                        </Typography>
                                                    )
                                                    :
                                                    (
                                                        <Typography variant="h6" align="center" marginTop={2}>
                                                            <Chip label="Expirado" sx={{ fontWeight: 'bold', backgroundColor: 'red'}} />
                                                        </Typography>
                                                    )

                                                )

                                            }

                                            
                                        </>
                                    }
                                />

                                {/* VALIDAMOS ICONO DE LA TARJETA */}

                                <IconButton >
                                    <DescriptionIcon 
                                        sx={{ fontSize: 120 }}
                                    />
                                </IconButton>

                                {/* FOOTER DE LA TARJETA */}

                                <CardActions disableSpacing>
                                    <IconButton onClick={() => handleDownload(file.ruta)}> 
                                        <DownloadIcon/>
                                    </IconButton>
                                </CardActions>
                            </Card>

                        </Grid>

                    ))
                }

            </Grid>

            {/* MODAL PARA SUBIR DOCUMENTOS */}

            <ModalSubirDocumento
                open={modalOpen}
                onClose={handleModalClose}
                refresh={buscarDocumentos}
                idOperador={idOperador}
            />

        </Box>

    )

}

export default OperadorDocumentos