import React, { useEffect, useState } from 'react';
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../theme";
import {api} from '../../config/axios';
import { useNavigate } from "react-router-dom";
import { marcarURL } from '../../config/functions';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

//COMPONENTES
import Header from '../../components/Header';
import MiTabla from '../../components/plantilla/MiTabla';

const OrdenesTransporte = () => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();

    // ===============================================
    // FUNCION PARA BUSCAR TRACTORES
    // ===============================================

    const [data, setData] = useState([]);

    const buscarOrden = async () => {

        const response = await api.get('transporte/ordenes');

        if(response.data !== "Sin Resultados"){

            setData(response.data);

        }else{

            setData([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        {
            field: "id",
            headerName: "# Viaje",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "operador",
            headerName: "Operador",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "tractor",
            headerName: "Tractor",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "activo",
            headerName: "Estado",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => 
                params.value === 'Cerrado' 
                ?
                <Button variant="contained" color="success" sx={{color: "white"}}><b>Cerrado</b></Button>
                :
                <Button variant="contained" color="warning" sx={{color: 'white', fontWeight: 'bold'}}><b>En Curso</b></Button>
        },

    ];

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const handleRowClick = (params) => {

        const idOrden = params.row.id;

        navigate('/datos_orden', { state: { idOrden: idOrden } })

    }

    // ===============================================
    // USE EFFECT PARA LLAMAR A LAS FUNCIONES
    // ===============================================

    useEffect(() => {

        buscarOrden();

        marcarURL('ordenes_transporte');

    },[]);

    // ===============================================

    return (

        <Box m="20px">

            {/* HEADER */}

            <Header title="Ordenes" subtitle="Ordenes de Transporte"/>

            {/* BOTON PARA ABRIR MODAL */}

            <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.redAccent[400]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={() => navigate('/crear_orden_transporte')}
            >
                Agregar Orden
            </Button>

            {/* TABLA */}

            <MiTabla
                data={data}
                columns={columns}
                rowClick={handleRowClick}
            />

        </Box>

    )

}

export default OrdenesTransporte