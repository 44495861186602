import React, { useEffect, useState } from 'react';
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import {api} from '../../../config/axios';
import { marcarURL } from '../../../config/functions';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

//COMPONENTES
import Header from '../../../components/Header';
import MiTabla from '../../../components/plantilla/MiTabla';
import ModalCrearWarehouse from './modales/ModalCrearWarehouse';
import ModalEditarWarehouse from './modales/ModalEditarWarehouse';

const Warehouse = () => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // FUNCION PARA BUSCAR PERFILES
    // ===============================================

    const [data, setData] = useState([]);

    // -----------------------------------------------

    const buscarWarehouse = async () => {

        const response = await api.get('warehouse');

        if(response.data !== "Sin Resultados"){

            setData(response.data);

        }else{

            setData([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        {
            field: "warehouse_name",
            headerName: "Warehouse",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "direccion",
            headerName: "Direccion",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "cliente",
            headerName: "Cliente",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "estado",
            headerName: "Zona",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
            renderCell: (params) => `${params.row.estado}, ${params.row.pais}`
        },
        {
            field: "contacto",
            headerName: "Contacto",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "telefono",
            headerName: "Telefono",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },

    ];

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR MODALES
    // ===============================================

    const [modal, setModal] = useState({

        crear: false,
        editar: false

    })

    // -----------------------------------------------

    const handleOpenModal = (target) => {

        setModal({ ...modal, [target]: true});

    };

    // -----------------------------------------------
    
    const handleCloseModal = (target) => {

        setModal({ ...modal, [target]: false});

    };

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const [formValues, setFormValues] = useState([])

    const handleRowClick = (params) => {

        let data = params.row;

        data.horario = JSON.parse(data.horario);

        setFormValues(data);

        handleOpenModal('editar');

    }

    // ===============================================
    // USE EFFECT PARA LLAMAR A LAS FUNCIONES
    // ===============================================

    useEffect(() => {

        buscarWarehouse();

        marcarURL('warehouse');

    },[]);

    // ===============================================

    return (

        <Box m="20px">

            {/* HEADER */}

            <Header title="Warehouse" subtitle="Catalogo de Warehouse"/>

            {/* BOTON PARA ABRIR MODAL */}

            <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.redAccent[400]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={() => handleOpenModal('crear')}
            >
                Agregar Warehouse
            </Button>

            {/* TABLA */}

            <MiTabla
                data={data}
                columns={columns}
                rowClick={handleRowClick}
            />

            {/* MODAL PARA AGREGAR CARRIER */}

            <ModalCrearWarehouse
                open={modal.crear}
                onClose={handleCloseModal}
                refresh={buscarWarehouse}
            />

            {/* MODAL PARA EDITAR CARRIER */}

            <ModalEditarWarehouse
                open={modal.editar}
                onClose={handleCloseModal}
                refresh={buscarWarehouse}
                data={formValues}
            />

        </Box>

    )

}

export default Warehouse