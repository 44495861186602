import React, { useEffect, useState } from 'react';
import { Button, Box, Container, Grid  } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { sendFormData, api } from '../../../../config/axios';
import Swal from 'sweetalert2';
import { styled } from '@mui/system'

// ICONOS
import SaveIcon from '@mui/icons-material/Save';

// COMPONENTES
import TextInput from '../../../../components/inputs/TextInput';
import MyPhoneInput from '../../../../components/inputs/MyPhoneInput';
import MyInputFile from '../../../../components/inputs/MyInputFile';
import MySwitch from '../../../../components/inputs/MySwitch';

const EditarOperador = ({ idOperador = 0 }) => {

    // ===============================================
    // BODY DEL MODAL
    // ===============================================

    const ScrollableContainer = styled(Container)({

        maxHeight: '50vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',

    });

    // ===============================================
    // ESTADO PARA LA FOTO DE LA IMAGEN
    // ===============================================

    const [fotoUrl, setFotoUrl] = useState('');

    // ===============================================
    // FUNCION PARA BUSCAR DATOS DEL TRACTOR
    // ===============================================

    const [formValues, setFormValues] = useState({

        nombres: '', 
        apellidos: '', 
        telefono: '',
        activo: '',
        ruta: '',
        foto_nueva: ''

    });

    // -----------------------------------------------

    const buscarDatosOperador = async () => {

        const response = await api.get(`operadores/${idOperador}`);

        if(response.data !== "Sin Resultados"){

            // GUARDAMOS LOS DATOS DEL TRACTOR

            let info = response.data[0];
            info.foto_nueva = '';

            // CONVERTIMOS LA RUTA PARA ACCEDER A LA FOTO

            const rutaFoto = `https://documents.k9system.com.mx/av_solutions${info.ruta}`;

            // GUARDAMOS LA RUTA DE LA FOTO

            setFotoUrl(rutaFoto)

            setFormValues(info);

        }else{

            setFormValues([]);

        }

    }

    // ===============================================
    // USEFFECT PARA LLAMAR A FUNCION
    // ===============================================

    useEffect(() => {

        if(idOperador !== 0){

            buscarDatosOperador();

        }

    },[idOperador]);

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = async (values) => {

        const url = 'operadores/update';

        const result = await sendFormData(url, values, "PUT");

        if(result.success === true){

            Swal.fire({
                icon: 'success',
                title: result.message,
            })

        }

    }

    // ===============================================

    return (

        <Formik
            initialValues={formValues}
            enableReinitialize={true}
            validationSchema={Yup.object({
                nombres: Yup.string().required('Requerido'),
                apellidos: Yup.string().required('Requerido'),
                foto_nueva: Yup.mixed()
                    .test('fileType', 'Formato de archivo no válido', (value) => {
                        if(value === undefined){
                            return true;
                        }
                        else{
                            return value && ['image/png', 'image/jpeg', 'image/jpg'].includes(value.type);
                        }
                    })
                    .test('fileSize', 'El archivo es demasiado grande', (value) => {
                        if(value === undefined){
                            return true;
                        }else{
                            return value && value.size <= 10485760; // 10MB
                        }
                    })
            })}
            onSubmit={(values) => {
                handleSubmit(values)
            }}
        >
        {({ values }) => (

            // BODY DEL FORMULARIO

            <Form encType='multipart/form-data'>

                <Grid 
                    container
                    spacing={2}
                    paddingBottom={2}
                    marginTop={2}
                >

                    {/* SECCION PARA EL FORMULARIO */}

                    <Grid item md={7} xs={12}>

                        <Grid container spacing={2}>

                            {/* NOMBRE DEL OPERADOR */}

                            <Grid item xs={12}>

                                <TextInput
                                    name="nombres"
                                    label="Nombre Operador"
                                />

                            </Grid>

                            {/* APELLIDO DEL OPERADOR */}

                            <Grid item xs={12}>

                                <TextInput
                                    name="apellidos"
                                    label="Apellido Operador"
                                />

                            </Grid>

                            {/* TELEFONO DEL OPERADOR */}

                            <Grid item md={5} xs={12}>

                                <MyPhoneInput
                                    name="telefono"
                                    label="Telefono Operador"
                                />

                            </Grid>

                            {/* INPUT PARA LA FOTO */}

                            <Grid item md={4} xs={12}>

                                <MyInputFile
                                    name="foto_nueva"
                                    label="Foto Del Tractor"
                                />

                            </Grid>

                            {/* ACTIVO */}

                            <Grid item md={3} xs={12}>

                                <MySwitch
                                    name="activo"
                                    label="Operador Activo"
                                />

                            </Grid>

                            <Grid item xs={12}>

                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>

                                    <Button 
                                        type="submit" 
                                        variant="contained" 
                                        sx={{color: "white"}} 
                                        color="success" 
                                        startIcon={<SaveIcon />}
                                    >
                                        Guardar
                                    </Button>

                                </Box>

                            </Grid>

                        </Grid>

                    </Grid>

                    {/* SECCION PARA LA FOTO */}

                    <Grid item md={5} xs={12}>

                        <Grid container>

                            {/* AREA DE LA FOTO */}

                            <Grid item xs={12}>

                                <ScrollableContainer>

                                {
                                    values.foto_nueva !== ''
                                    ?
                                    (
                                        <img
                                            src={URL.createObjectURL(values.foto_nueva)}
                                            alt="Imagen seleccionada"
                                            style={{ 
                                                width: '100%', 
                                                maxHeight: '100vh', 
                                                marginTop: '10px' 
                                            }}
                                        />
                                    )
                                    :
                                    (
                                        <img
                                            src={fotoUrl}
                                            alt="Imagen seleccionada"
                                            style={{ 
                                                width: '100%', 
                                                maxHeight: '100vh', 
                                                marginTop: '10px' 
                                            }}
                                        />
                                    )
                                }

                                </ScrollableContainer>

                            </Grid>

                        </Grid>

                    </Grid>

                </Grid>

            </Form>

        )}
        </Formik>

    )

}

export default EditarOperador