import React from 'react';
import { Button, Modal, Paper, Typography, Box, Container, Grid  } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {api} from '../../../../config/axios';
import Swal from 'sweetalert2';
import { styled } from '@mui/system'
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';

// COMPONENTES
import TextInput from '../../../../components/inputs/TextInput';
import MyNumberInput from '../../../../components/inputs/MyNumberInput';
import MyMoneyInput from '../../../../components/inputs/MyMoneyInput';

const CrearCliente = ({open, onClose, refresh}) => {

    // ===============================================
    // BODY DEL MODAL
    // ===============================================

    const ScrollableContainer = styled(Container)({
        maxHeight: '60vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });

    // ===============================================
    // VALIDAR SI SE VE DESDE UN MOVIL
    // ===============================================

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = (values) => {

        const url = 'clientes/crear';

        api.post(url, values)
        .then((response) => {

            if(response.data.success === true){

                onClose('crear');

                refresh();

                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                })

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    // ===============================================

    return (

        <Modal
            open={open}
            onClose={onClose}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >

            <Formik
                initialValues={{
                    nombre_comercial: '', 
                    razon_social: '', 
                    correo: '',
                    direccion: '',
                    credito: '',
                    rfc: '',
                    iva: '',
                }}
                validationSchema={Yup.object({
                    nombre_comercial: Yup.string().required('Requerido'),
                    correo: Yup.string().email('Ingrese un correo Valido').required('Requerido'),
                    iva: Yup.string().required('Requerido'),
                    credito: Yup.string().required('Requerido')
                })}
                onSubmit={(values) => {
                    handleSubmit(values)
                }}
            >
            {({ isSubmitting }) => (

                <Paper
                    sx={{ 
                        width: isNonMobile ? `50%` : '90%', 
                        p: 3, 
                        bgcolor: colors.primary[1000] 
                    }}
                >

                    <Form>

                        {/* HEADER DEL MODAL */}

                        <Typography variant="h3" align="center" marginBottom={1}>
                            Agregar Cliente
                        </Typography>

                        {/* BODY DEL MODAL */}

                        <ScrollableContainer>

                            <Grid 
                                container
                                spacing={2}
                                paddingBottom={2}
                                marginTop={2}
                            >

                                {/* NOMBRE COMERCIAL DEL CLIENTE */}

                                <Grid item xs={6}>

                                    <TextInput
                                        name="nombre_comercial"
                                        label="Nombre Del Cliente"
                                    />

                                </Grid>

                                {/* RAZON SOCIAL DEL CLIENTE */}

                                <Grid item xs={6}>

                                    <TextInput
                                        name="razon_social"
                                        label="Razon Social Del Cliente"
                                    />

                                </Grid>

                                {/* CORREO ELECTRONICO DEL CLIENTE */}

                                <Grid item xs={6}>

                                    <TextInput
                                        name="correo"
                                        label="Correo Electronico"
                                    />

                                </Grid>

                                {/* DIRECCION DEL CLIENTE */}

                                <Grid item xs={6}>

                                    <TextInput
                                        name="direccion"
                                        label="Direccion"
                                    />

                                </Grid>

                                {/* RFC DEL CLIENTE */}

                                <Grid item xs={4}>

                                    <TextInput
                                        name="rfc"
                                        label="RFC"
                                    />

                                </Grid>

                                {/* IVA DEL CLIENTE */}

                                <Grid item xs={4}>

                                    <MyNumberInput
                                        name="iva"
                                        label="IVA (%)"
                                    />

                                </Grid>

                                {/* CREDITO DEL CLIENTE */}

                                <Grid item xs={4}>

                                    <MyMoneyInput
                                        name="credito"
                                        label="Credito"
                                    />

                                </Grid>

                            </Grid>

                        </ScrollableContainer>

                        {/* FOOTER DEL MODAL */}

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>

                            <Button variant="contained" color="primary" onClick={() => onClose('crear')}>
                                Cancelar
                            </Button>

                            <Button 
                                type="submit" 
                                variant="contained" 
                                sx={{color: "white"}} 
                                color="success" 
                                startIcon={<SaveIcon />}
                                disabled={isSubmitting}
                            >
                                Guardar
                            </Button>

                        </Box>

                    </Form>

                </Paper>

            )}
            </Formik>

        </Modal>

    )

}

export default CrearCliente