import React from 'react';
import { Box, Grid } from "@mui/material";
import { useLocation } from 'react-router-dom';
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';

// COMPONENTES
import EditarCliente from '../clientes_datos/EditarCliente';
import ClienteDocumentos from '../clientes_documentos/ClienteDocumentos';

const ClienteVista = () => {

    // ===============================================
    // OBTENER INFORMACION DEL CURSO
    // ===============================================

    const location = useLocation();
    const { data } = location.state;

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================

    return (

        <Box m="20px">

            <Grid container>

                {/* SECCION PARA EL FOMULARIO DEL CLIENTE */}

                <Grid 
                    item md={5} 
                    xs={12}
                    sx={{
                        padding: '20px',
                    }}
                >

                    <EditarCliente data={data} />

                </Grid>

                {/* SECCION PARA DOCUMENTOS DEL CLIENTE */}

                <Grid 
                    item 
                    md={7} 
                    xs={12}
                    sx={{
                        backgroundColor: colors.grey[900],
                        padding: '20px',
                        borderRadius: '10px',
                        height: '85vh'
                    }}
                >

                    <ClienteDocumentos
                        data={data}
                    />

                </Grid>

            </Grid>

        </Box>

    )

}

export default ClienteVista