import React, { useEffect, useState } from 'react';
import { Button, Box, Grid  } from "@mui/material";
import { Formik, Form } from 'formik';
import { api } from '../../../../config/axios';
import Swal from 'sweetalert2';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';

// COMPONENTES
import TextInput from '../../../../components/inputs/TextInput';

const OperadorDatosNomina = ({ idOperador = 0 }) => {

    // ===============================================
    // FUNCION PARA BUSCAR DATOS DEL TRACTOR
    // ===============================================

    const [formValues, setFormValues] = useState({

        rfc: '', 
        curp: '', 
        licencia: '',
        imss: '',
        pension_alimenticia: '',
        banco: '',
        clave_interbancaria: '',

    });

    // -----------------------------------------------

    const buscarDatosNomina = async () => {

        const response = await api.get(`operadores/${idOperador}`);

        if(response.data[0]['datos_nomina'] !== null){

            setFormValues(JSON.parse(response.data[0]['datos_nomina']));

        }

    }

    // ===============================================
    // USEFFECT PARA LLAMAR A FUNCION
    // ===============================================

    useEffect(() => {

        if(idOperador !== 0){

            buscarDatosNomina();

        }

    },[idOperador]);

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = (values) => {

        // DATOS PARA EL FORMULARIO

        const data = {
            id: idOperador,
            datos_nomina: JSON.stringify(values)
        }

        // URL DEL ENDPOINT

        const url = 'operadores/update/datos/nomina';

        api.put(url, data)
        .then((response) => {

            if(response.data.success === true){

                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                })

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    // ===============================================

    return (

        <Formik
            initialValues={formValues}
            enableReinitialize={true}
            onSubmit={(values) => {
                handleSubmit(values)
            }}
        >

            {/* BODY DEL FORMULARIO */}

            <Form>

                <Grid 
                    container
                    spacing={2}
                    paddingBottom={2}
                    marginTop={2}
                >

                    {/* RFC */}

                    <Grid item md={6} xs={12}>

                        <TextInput
                            name="rfc"
                            label="RFC Del operador"
                        />

                    </Grid>

                    {/* CURP */}

                    <Grid item md={6} xs={12}>

                        <TextInput
                            name="curp"
                            label="CURP Del operador"
                        />

                    </Grid>

                    {/* IMSS */}

                    <Grid item md={6} xs={12}>

                        <TextInput
                            name="imss"
                            label="IMSS Del operador"
                        />

                    </Grid>

                    {/* BANCO */}

                    <Grid item md={6} xs={12}>

                        <TextInput
                            name="banco"
                            label="Banco Del operador"
                        />

                    </Grid>

                    {/* CLAVE INTERBANCARIA */}

                    <Grid item md={6} xs={12}>

                        <TextInput
                            name="clave_interbancaria"
                            label="Clave Interbancaria"
                        />

                    </Grid>

                    {/* FOOTER */}

                    <Grid item xs={12}>

                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>

                            <Button 
                                type="submit" 
                                variant="contained" 
                                sx={{color: "white"}} 
                                color="success" 
                                startIcon={<SaveIcon />}
                            >
                                Guardar
                            </Button>

                        </Box>

                    </Grid>
                    
                </Grid>

            </Form>

        </Formik>

    )

}

export default OperadorDatosNomina