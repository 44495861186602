import React, { useEffect, useState } from 'react';
import { useTheme, Button } from "@mui/material";
import { tokens } from "../../../../theme";
import { api } from '../../../../config/axios';

// COMPONENTES
import MiTabla from '../../../../components/plantilla/MiTabla';
import ModalConsultarIncidencia from './ModalConsultarIncidencia';

const Incidencias = ({ idOperador }) => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // FUNCION PARA BUSCAR TRACTORES
    // ===============================================

    const [data, setData] = useState([]);

    const buscarIncidentes = async () => {

        const response = await api.get(`operadores/incidencias/operador/${idOperador}`);

        if(response.data !== "Sin Resultados"){console.log(response.data)

            setData(response.data);

        }else{

            setData([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        {
            field: "orden",
            headerName: "Orden",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "user",
            headerName: "Subido Por",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "comentario",
            headerName: "Comentario",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "fecha",
            headerName: "Fecha",
            flex: 2,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => params.value.split('T')[0] 
        },

    ];

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR MODALES
    // ===============================================

    const [modal, setModal] = useState(false)

    // -----------------------------------------------

    const handleOpenModal = (target) => {

        setModal(true);

    };

    // -----------------------------------------------
    
    const handleCloseModal = (target) => {

        setModal(false);

    };

    // ===============================================
    // FUNCION PARA SELECCIONAR FILA
    // ===============================================

    const [formValues, setFormValues] = useState({})

    const handleRowClick = (params) => {

        setFormValues(params.row);

        handleOpenModal();

    }

    // ===============================================
    // USE EFFECT PARA LLAMAR A LAS FUNCIONES
    // ===============================================

    useEffect(() => {

        buscarIncidentes();

    },[]);

    // ===============================================

    return (

        <React.Fragment>

            {/* TABLA */}

            <MiTabla
                data={data}
                columns={columns}
                rowClick={handleRowClick}
            />

            {/* MODAL VISUALIZAR INCIDENCIA */}

            <ModalConsultarIncidencia
                open={modal}
                onClose={handleCloseModal}
                data={formValues}
            />

        </React.Fragment>

    )

}

export default Incidencias