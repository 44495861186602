import React, { useState } from 'react';
import { Typography, Box, Grid, BottomNavigation, BottomNavigationAction } from "@mui/material";
import { useLocation } from 'react-router-dom';
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';

// ICONOS
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DescriptionIcon from '@mui/icons-material/Description';
import AssignmentIcon from '@mui/icons-material/Assignment';

// COMPONENTES
import EditarTractor from '../tractor_datos/EditarTractor';
import TractorDocumentos from '../tractor_documentos/TractorDocumentos';
import TractorPlacas from '../tractor_placas/TractorPlacas';

const TractorVista = () => {

    // ===============================================
    // OBTENER INFORMACION DEL CURSO
    // ===============================================

    const location = useLocation();
    const { data } = location.state;

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // -----------------------------------------------

    const fontColor = colors.redAccent[500];
    const iconColor = colors.redAccent[500];

    // ===============================================
    // CATALOGO DE COMPONENTES
    // ===============================================

    const views = {

        datos: EditarTractor,
        placas: TractorPlacas,
        documentos: TractorDocumentos,

    }

    // ===============================================
    // OPCION DE COMPONENTE 
    // ===============================================

    const [opcion, setOpcion] = useState('datos');

    // ===============================================
    // COMPONENTE DINAMICO
    // ===============================================

    const ComponenteActual = views[opcion];

    // ===============================================

    return (

        <Box m="20px">

            <Grid container spacing={2} padding={2}>

                {/* HEADEAR */}

                <Grid item xs={12}>

                    <Typography variant="h1" align="center" fontWeight={'bold'}>
                        Unidad {data.numero_unidad}
                    </Typography>

                </Grid>

                {/* SECCION DE MENUS */}

                <Grid item xs={12}>

                    <BottomNavigation
                        showLabels
                        value={opcion}
                        onChange={(event, newValue) => {
                            setOpcion(newValue)
                        }}
                        sx={{borderRadius: '10px'}}
                    >
                        {/* DATOS DEL TRACTOR */}
                        <BottomNavigationAction
                            label="Tractor"
                            value='datos'
                            icon={<LocalShippingIcon style={{ color: opcion === 'datos' ? iconColor : 'inherit' }} />}
                            style={{ color: opcion === 'datos' ? fontColor : 'inherit' }}
                        />
                        {/* PLACAS DEL TRACTOR */}
                        <BottomNavigationAction
                            label="Placas"
                            value='placas'
                            icon={<AssignmentIcon style={{ color: opcion === 'placas' ? iconColor : 'inherit' }} />}
                            style={{ color: opcion === 'placas' ? fontColor : 'inherit' }}
                        />
                        {/* DOCUMENTOS DEL TRACTOR */}
                        <BottomNavigationAction
                            label="Documentos"
                            value='documentos'
                            icon={<DescriptionIcon style={{ color: opcion === 'documentos' ? iconColor : 'inherit' }} />}
                            style={{ color: opcion === 'documentos' ? fontColor : 'inherit' }}
                        />
                    </BottomNavigation>

                </Grid>

                {/* AREA DEL COMPONENTE A MOSTRAR */}

                <Grid item xs={12}>

                    <ComponenteActual
                        idTractor={data.id}
                    />

                </Grid>

            </Grid>

        </Box>

    )

}

export default TractorVista