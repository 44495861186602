import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Drawer } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import {modulos, iconos} from '../../data/modulosk9';
import Cookies from "js-cookie";

// ICONOS
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AppsIcon from '@mui/icons-material/Apps';
import LogoK9 from '../../assets/img/plantillak9/k9MiniLogo.png';

// ===============================================
// COMPONENTE PARA ITEMS DEL SIDEBAR
// ===============================================

const Item = ({ title, to, icon, selected, setSelected }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (

        <MenuItem
            active={selected === title}
            style={{
                color: colors.grey[100],
            }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </MenuItem>

    );

}

// ===============================================

const Sidebark9 = () => {

    // ===============================================
    // ESTADOS PARA EL SIDEBAR
    // ===============================================

    const [validModuleValues, setValidModuleValues] = useState([]);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState("Dashboard");
    const [isMobile, setIsMobile] = useState(false);

    // ===============================================
    // COLORES PARA EL COMPONENTE
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // VERIFICAR SI ES UN MOVIL
    // ===============================================

    const checkIsMobile = () => {

        setIsMobile(window.innerWidth < 768); // Puedes ajustar este valor según tus necesidades

    }; 

    // ===============================================
    // CONTROLAR ESTADO DEL SIDEBAR
    // ===============================================

    const toggleSidebar = () => {

        setIsCollapsed(!isCollapsed);

    };

    // ===============================================
    // USEEFFECT PARA CARGAR DATOS
    // ===============================================

    useEffect(() => {

        // OBTENEMOS EL LISTADO DE MODULOS ASIGNADOS

        const menus = JSON.parse(Cookies.get('menu'));

        // FILTRAMOS LOS MODULOS QUE VAMOS A UTILIZAR

        const agrupadosPorSeccion = menus.reduce((acumulador, objeto) => {

            const { seccion, ...restoDelObjeto } = objeto;

            acumulador[seccion] = acumulador[seccion] || [];
            acumulador[seccion].push(restoDelObjeto);

            return acumulador;

        }, {});

        // GUARDAMOS EL RESULTADO FINAL

        setValidModuleValues(agrupadosPorSeccion);

        // VALIDAMOS SI ES MOVIL

        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);

        return () => {

            window.removeEventListener('resize', checkIsMobile);

        };

    },[])

    // ===============================================

    return (

        <Box
            sx={{
                "& .pro-sidebar-inner": {
                    background: `${colors.primary[400]} !important`,
                    paddingRight: isCollapsed ? '0px' : '50px'
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important",
                },
                "& .pro-inner-item": {
                    padding: "5px 35px 5px 20px !important",
                },
                "& .pro-inner-item:hover": {
                    color: "#ff606c !important",
                },
                "& .pro-menu-item.active": {
                    color: "#ff0000 !important",
                },
                minHeight: '100vh',
                overflowY: 'auto',
                overflowX: 'hidden',
            }}
        >

            {/* Muestra un botón de menú en lugar del sidebar en dispositivos móviles */}

            {
                isMobile
                ?
                (
                    <IconButton
                        sx={{
                            zIndex: 1,
                            position: "absolute",
                            top: "20px",
                            left: "20px",
                            color: colors.grey[100],
                        }}
                        onClick={toggleSidebar}
                    >
                        <MenuOutlinedIcon />
                    </IconButton>
                )
                :
                (
                    <ProSidebar collapsed={isCollapsed} sx={{width: '100%'}}>

                        <Menu iconShape="square">

                            {/* LOGO AND MENU ICON */}

                            <MenuItem
                                onClick={() => setIsCollapsed(!isCollapsed)}
                                icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                                style={{
                                    margin: "10px 0 20px 0",
                                    color: colors.grey[100],
                                }}
                            >
                            {!isCollapsed && (

                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    ml="15px"
                                >

                                    <Typography variant="h3" color={colors.grey[100]}></Typography>
                                    
                                    <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                        <MenuOutlinedIcon />
                                    </IconButton>

                                </Box>

                            )}
                            </MenuItem>

                            {!isCollapsed && (

                                <Box mb="25px">

                                    <Box display="flex" justifyContent="center" alignItems="center">

                                        <img
                                            alt="profile-user"
                                            width="140px"
                                            height="140px"
                                            src={LogoK9}
                                            style={{ cursor: "pointer", borderRadius: "30%", backgroundColor: 'white', padding: '10px' }}
                                        />

                                    </Box>

                                </Box>

                            )}

                            {/*  */}

                            <Box paddingLeft={isCollapsed ? undefined : "10%"}>

                                {/* DASHBOARD */}

                                <Item
                                    title="Dashboard"
                                    to="/dashboard"
                                    icon={<HomeOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                {/* MENU DINAMICO */}

                                {
                                    Object.keys(validModuleValues).map((item, index) => {

                                        // VALIDAMOS SI SE TRATA DEL CATALOGO

                                        if(item === 'Catalogo'){

                                            return (

                                                <Item
                                                    title="Catalogos"
                                                    to="/catalogos"
                                                    icon={<AppsIcon />}
                                                    selected={selected}
                                                    setSelected={setSelected}
                                                    key={index}
                                                />

                                            );

                                        }else{

                                            // FILTRAMOS PARA SACAR EL SUBHEADER DEL MODULO

                                            const subheader = modulos.filter(obj => obj.type === 'subheader' && obj.label === item)[0];

                                            // SACAMOS EL ICONO A UTILIZAR PARA EL SUBHEADER

                                            const SubheaderIcon = iconos[subheader.icono];

                                            return (

                                                <SubMenu
                                                    key={index}
                                                    title={subheader.label}
                                                    icon={<SubheaderIcon/>}
                                                >
                                                {
                                                    // RECORREMOS LOS VALORES DEL SUBMENU

                                                    validModuleValues[item].map((modulo, index2) => {

                                                        // OBTENEMOS LOS DATOS DEL MODULO

                                                        const datosModulo = modulos.filter(obj2 => obj2.type === 'menu' && obj2.modulo === modulo.modulo)[0];

                                                        // OBTENEMOS EL ICONO

                                                        const ItemIcon = iconos[datosModulo.icono];

                                                        return (

                                                            <Item
                                                                title={datosModulo.modulo}
                                                                to={`/${datosModulo.url}`}
                                                                icon={<ItemIcon />}
                                                                selected={selected}
                                                                setSelected={setSelected}
                                                            />
            
                                                        );

                                                    })

                                                }
                                                </SubMenu>

                                            );

                                        }

                                    })
                                }          

                            </Box>

                        </Menu>

                    </ProSidebar>
                )
            }

            {/* SECCION PARA RENDERIZAR SI SE ABRE DESDE UN DISPOSITIVO MOVIL */}

            {
                isMobile && (

                    <Drawer
                        anchor="left"
                        open={isCollapsed}
                        onClose={toggleSidebar}
                        variant="temporary"
                    >

                    <ProSidebar collapsed={isCollapsed} sx={{width: '100%'}}>

                        <Menu iconShape="square">

                            {/* LOGO AND MENU ICON */}

                            <MenuItem
                                onClick={() => setIsCollapsed(!isCollapsed)}
                                icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                                style={{
                                    margin: "10px 0 20px 0",
                                    color: colors.grey[100],
                                }}
                            >
                                {

                                    !isCollapsed && (

                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            ml="15px"
                                        >

                                            <Typography variant="h3" color={colors.grey[100]}></Typography>

                                            <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                                <MenuOutlinedIcon />
                                            </IconButton>

                                        </Box>
                                    )
                                }
                            </MenuItem>

                            {
                                !isCollapsed && (

                                    <Box mb="25px">

                                        <Box display="flex" justifyContent="center" alignItems="center">

                                            <img
                                                alt="profile-user"
                                                width="140px"
                                                height="140px"
                                                src={LogoK9}
                                                style={{ 
                                                    cursor: "pointer", 
                                                    borderRadius: "30%", 
                                                    backgroundColor: 'white', 
                                                    padding: '10px' 
                                                }}
                                            />
                                            
                                        </Box>

                                    </Box>

                                )
                            }

                            {/*  */}

                            <Box paddingLeft={isCollapsed ? undefined : "10%"}>

                                {/* DASHBOARD */}

                                <Item
                                    title="Dashboard"
                                    to="/dashboard"
                                    icon={<HomeOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                {/* MENU DINAMICO */}

                                {
                                    Object.keys(validModuleValues).map((item, index) => {

                                        // VALIDAMOS SI SE TRATA DEL CATALOGO

                                        if(item === 'Catalogo'){

                                            return (

                                                <Item
                                                    title="Catalogos"
                                                    to="/catalogos"
                                                    icon={<AppsIcon />}
                                                    selected={selected}
                                                    setSelected={setSelected}
                                                    key={index}
                                                />

                                            );

                                        }else{

                                            // FILTRAMOS PARA SACAR EL SUBHEADER DEL MODULO

                                            const subheader = modulos.filter(obj => obj.type === 'subheader' && obj.label === item)[0];

                                            // SACAMOS EL ICONO A UTILIZAR PARA EL SUBHEADER

                                            const SubheaderIcon = iconos[subheader.icono];

                                            return (

                                                <SubMenu
                                                    key={index}
                                                    title={subheader.label}
                                                    icon={<SubheaderIcon/>}
                                                >
                                                {
                                                    // RECORREMOS LOS VALORES DEL SUBMENU

                                                    validModuleValues[item].map((modulo, index2) => {

                                                        // OBTENEMOS LOS DATOS DEL MODULO

                                                        const datosModulo = modulos.filter(obj2 => obj2.type === 'menu' && obj2.modulo === modulo.modulo)[0];

                                                        // OBTENEMOS EL ICONO

                                                        const ItemIcon = iconos[datosModulo.icono];

                                                        return (

                                                            <Item
                                                                title={datosModulo.modulo}
                                                                to={`/${datosModulo.url}`}
                                                                icon={<ItemIcon />}
                                                                selected={selected}
                                                                setSelected={setSelected}
                                                            />
            
                                                        );

                                                    })

                                                }
                                                </SubMenu>

                                            );

                                        }

                                    })
                                }   

                            </Box>

                        </Menu>

                    </ProSidebar>

                </Drawer>
            )}

        </Box>

    )

}

export default Sidebark9