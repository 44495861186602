import React, { useEffect, useMemo, useState } from 'react';
import { Button, Box, Grid, Divider } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {api} from '../../../config/axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';

// COMPONENTES
import MyAutoComplete from '../../../components/inputs/MyAutoComplete';
import TextInput from '../../../components/inputs/TextInput';
import MyAutoCompleteSublabels from '../../../components/inputs/MyAutoCompleteSublabels';
import MyDateInput from '../../../components/inputs/MyDateInput';
import MyHourInput from '../../../components/inputs/MyHourInput';

const EditarOrdenTransporte = ({ orderData, refresh }) => {

    // ===============================================
    // FUNCION PARA BUSCAR OPERADORES
    // ===============================================

    const [operadores, setOperadores] = useState([]);

    const buscarOperadores = async () => {

        const response = await api.get('operadores/select');

        if(response.data !== "Sin Resultados"){

            setOperadores(response.data);

        }else{

            setOperadores([]);

        }

    }

    // ===============================================
    // FUNCION PARA BUSCAR TRACTORES
    // ===============================================

    const [tractores, setTractores] = useState([]);

    const buscarTractores = async () => {

        const response = await api.get('tractores/select');

        if(response.data !== "Sin Resultados"){

            setTractores(response.data);

        }else{

            setTractores([]);

        }

    }

    // ===============================================
    // FUNCION PARA BUSCAR CLIENTES
    // ===============================================

    const [clientes, setClientes] = useState([]);

    const buscarClientes = async () => {

        const response = await api.get('clientes/select');

        if(response.data !== "Sin Resultados"){

            setClientes(response.data);

        }else{

            setClientes([]);

        }

    }

    // ===============================================
    // FUNCION PARA BUSCAR WAREHOUSE
    // ===============================================

    const [warehouse, setWarehouse] = useState([]);

    const buscarWarehouse = async () => {

        const response = await api.get('warehouse/select');

        if(response.data !== "Sin Resultados"){

            setWarehouse(response.data);

        }else{

            setWarehouse([]);

        }

    }

    // ===============================================
    // USE MEMO PARA BUSCAR TRACTORES Y OPERADORES
    // ===============================================

    const buscarDatosCatalogos = useMemo(() => {

        return {

            buscarTractores,
            buscarOperadores,
            buscarClientes,
            buscarWarehouse

        }

    },[]);

    // ===============================================
    // ESTADO PARA LOS FORMULARIOS
    // ===============================================

    const [formData, setFormData] = useState({

        rel_cliente_operacion: '',
        rel_cliente_facturacion: '',
        rel_operador: '',
        rel_tractor: '',
        caja: '',
        caja_placas: '',
        warehouse_origen: '',
        warehouse_destino: '',
        fecha_cita: '',
        hora_cita: '',
        conceptoIngreso: '',
        montoIngreso: '',
        conceptoEgreso: '',
        montoEgreso: ''

    });

    // ===============================================
    // USE EFFECT PARA LLAMAR FUNCIONES
    // ===============================================

    useEffect(() => {

        // LLAMAMOS LAS FUNCIONES DESDE USEMEMO

        buscarDatosCatalogos.buscarOperadores();
        buscarDatosCatalogos.buscarTractores();
        buscarDatosCatalogos.buscarClientes();
        buscarDatosCatalogos.buscarWarehouse();

        // ACTUALIZAMOS VALORES DEL FORMULARIO

        setFormData(orderData)

    },[orderData]);

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = (values) => {

        const url = 'transporte/ordenes/update';

        values.rel_user_update = Cookies.get('idUser');

        //console.log(values)

        api.put(url, values)
        .then((response) => {

            if(response.data.success === true){

                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                })

                refresh();

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    // ===============================================

    return (

        <Box m="20px">

            <Formik
                initialValues={formData}
                enableReinitialize={true}
                validationSchema={Yup.object({
                    rel_cliente_operacion: Yup.string().required('Requerido'),
                    rel_cliente_facturacion: Yup.string().required('Requerido'),
                    rel_operador: Yup.string().required('Requerido'),
                    rel_tractor: Yup.string().required('Requerido'),
                    caja: Yup.string().required('Requerido'),
                    caja_placas: Yup.string().required('Requerido'),
                    warehouse_origen: Yup.string().required('Requerido'),
                    warehouse_destino: Yup.string().required('Requerido'),
                    fecha_cita: Yup.string().required('Requerido'),
                    hora_cita: Yup.string().required('Requerido'),
                })}
                onSubmit={(values) => {
                    handleSubmit(values)
                }}
            >
            {({ values, setFieldValue, isSubmitting }) => (

                <Form>

                    {/* BODY DEL MODAL */}

                    <Grid
                        container
                        spacing={2}
                        paddingBottom={2}
                        marginTop={2}
                    >

                        {/* SECCION PARA DATOS DE LA ORDEN */}

                        <Grid item xs={12}>

                            <Divider>Datos de la Orden</Divider>

                        </Grid>

                        {/* CLIENTE OPERACION */}

                        <Grid item md={3} xs={12}>

                            <MyAutoComplete
                                name="rel_cliente_operacion"
                                label="Cliente Operacion"
                                options={clientes}
                            />

                        </Grid>

                        {/* CLIENTE FACTURACION */}

                        <Grid item md={3} xs={12}>

                            <MyAutoComplete
                                name="rel_cliente_facturacion"
                                label="Cliente Facturacion"
                                options={clientes}
                            />

                        </Grid>

                        {/* OPERADOR */}

                        <Grid item md={3} xs={12}>

                            <MyAutoComplete
                                name="rel_operador"
                                label="Operador"
                                options={operadores}
                            />

                        </Grid>

                        {/* NOMBRE DEL TRACTOR */}

                        <Grid item md={3} xs={12}>

                            <MyAutoComplete
                                name="rel_tractor"
                                label="Tractor"
                                options={tractores}
                            />

                        </Grid>

                        {/* DATOS PARA LA CAJA */}

                        <Grid item xs={12}>

                            <Divider>Datos de la Caja</Divider>

                        </Grid>

                        {/* INPUT PARA CAJA */}

                        <Grid item md={2} xs={0}></Grid>

                        <Grid item md={4} xs={12}>

                            <TextInput
                                name="caja"
                                label="Caja"
                            />

                        </Grid>

                        {/* INPUT PARA PLACAS DE CAJA */}

                        <Grid item md={4} xs={12}>

                            <TextInput
                                name="caja_placas"
                                label="Placas"
                            />

                        </Grid>

                        <Grid item md={2} xs={0}></Grid>

                        {/* SECCION PARA DATOS DEL WAREHOUSE */}

                        <Grid item xs={12}>

                            <Divider>Datos de los Warehouse</Divider>

                        </Grid>

                        {/* WAREHOUSE DE ORIGEN */}

                        <Grid item md={6} xs={12}>

                            <MyAutoCompleteSublabels
                                options={warehouse}
                                label="Origen"
                                name="warehouse_origen"
                            />

                        </Grid>

                        {/* WAREHOUSE DE DESTINO */}

                        <Grid item md={6} xs={12}>

                            <MyAutoCompleteSublabels
                                name="warehouse_destino"
                                label="Destino"
                                options={warehouse}
                            />

                        </Grid>

                        {/* SECCION PARA DATOS DEL WAREHOUSE */}

                        <Grid item xs={12}>

                            <Divider>Fecha</Divider>

                        </Grid>

                        {/* FECHA DE CITA */}

                        <Grid item md={2} xs={0}></Grid>

                        <Grid item xs={4}>

                            <MyDateInput
                                name='fecha_cita'
                                label='Fecha de Cita'
                            />

                        </Grid>

                        {/* HORA DE CITA */}

                        <Grid item xs={4}>

                            <MyHourInput
                                name='hora_cita'
                                label='Hora de Cita'
                            />

                        </Grid>

                        <Grid item md={2} xs={0}></Grid>

                    </Grid>

                    {/* FOOTER DEL MODAL */}

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>

                        <Button 
                            type="submit" 
                            variant="contained" 
                            sx={{color: "white"}} 
                            color="success" 
                            startIcon={<SaveIcon />}
                        >
                            Guardar
                        </Button>

                    </Box>

                </Form>

            )}
            </Formik>

        </Box>

    )

}

export default EditarOrdenTransporte