import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';

// ICONOS
import DeleteIcon from '@mui/icons-material/Delete';

const TablaIngresos = ({ data, deleteData }) => {

    // ===============================================
    // COLUMNAS PARA INGRESOS
    // ===============================================



    // ===============================================

    return (

        <Table aria-label="simple table">

            <TableHead>

                <TableRow>
                    <TableCell align="center">Concepto</TableCell>
                    <TableCell align="center">Monto</TableCell>
                    <TableCell align="center">Moneda</TableCell>
                    <TableCell align="center"></TableCell>
                </TableRow>

            </TableHead>

            <TableBody>
            {
                data.map((row, index) => (
                    <TableRow
                        key={row.concepto}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell align="center">{row.concepto}</TableCell>
                        <TableCell align="center">{row.monto}</TableCell>
                        <TableCell align="center">{row.moneda}</TableCell>
                        <TableCell align="center">
                            <Button variant="contained" color="primary" sx={{ backgroundColor: 'red'}} onClick={() => deleteData(index)}>
                                <DeleteIcon/>
                            </Button>
                        </TableCell>
                    </TableRow>
                ))
            }
            </TableBody>

        </Table>
        
    )

}

export default TablaIngresos