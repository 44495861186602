import React, { useEffect, useState } from 'react';
import { Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import {api} from '../../../config/axios';
import { useNavigate } from "react-router-dom";
import { marcarURL } from '../../../config/functions';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

//COMPONENTES
import Header from '../../../components/Header';
import MiTabla from '../../../components/plantilla/MiTabla';
import CrearTractor from './tractor_datos/CrearTractor';

const Tractores = () => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();

    // ===============================================
    // FUNCION PARA BUSCAR TRACTORES
    // ===============================================

    const [data, setData] = useState([]);

    // -----------------------------------------------

    const buscarTractores = async () => {

        const response = await api.get('tractores');

        if(response.data !== "Sin Resultados"){

            setData(response.data);

        }else{

            setData([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        {
            field: "numero_unidad",
            headerName: "Tractor",
            flex: 2,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "modelo",
            headerName: "Modelo",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "marca",
            headerName: "Marca",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "activo",
            headerName: "Estado",
            flex: 2,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => 
                params.value === 1 
                ?
                <Button variant="contained" color="success" sx={{color: "white"}}><b>Activo</b></Button>
                :
                <Button variant="contained" color="error"><b>Inactivo</b></Button>
        },

    ];

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR MODALES
    // ===============================================

    const [modal, setModal] = useState(false)

    // -----------------------------------------------

    const handleOpenModal = () => {

        setModal(true);

    };

    // -----------------------------------------------
    
    const handleCloseModal = () => {

        setModal(false);

    };

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const handleRowClick = (params) => {

        const data = params.row;

        navigate('/tractor_detalles', { state: { data: data } });

    }

    // ===============================================
    // USE EFFECT PARA LLAMAR A LAS FUNCIONES
    // ===============================================

    useEffect(() => {

        buscarTractores();

        marcarURL('tractores');

    },[]);

    // ===============================================

    return (

        <Box m="20px">

            {/* HEADER */}

            <Header title="Tractores" subtitle="Catalogo de Tractores"/>

            {/* BOTON PARA ABRIR MODAL */}

            <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.redAccent[400]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={() => handleOpenModal()}
            >
                Agregar Tractor
            </Button>

            {/* TABLA */}

            <MiTabla
                data={data}
                columns={columns}
                rowClick={handleRowClick}
            />

            {/* MODAL PARA CREAR TRACTOR */}

            <CrearTractor
                open={modal}
                onClose={handleCloseModal}
                refresh={buscarTractores}
            />

        </Box>

    )

}

export default Tractores