import React from 'react';
import { Button, Modal, Paper, Typography, Box, Container, Grid } from "@mui/material";
import { Formik, Form } from 'formik';
import { styled } from '@mui/system'
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';

// COMPONENTES
import TextAreaInput from '../../../../components/inputs/TextAreaInput';
import TextInput from '../../../../components/inputs/TextInput';

const ModalConsultarIncidencia = ({open, onClose, data}) => {

    // ===============================================
    // BODY DEL MODAL
    // ===============================================

    const ScrollableContainer = styled(Container)({
        maxHeight: '60vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });

    // ===============================================
    // VALIDAR SI SE VE DESDE UN MOVIL
    // ===============================================

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================

    return (

        <Modal
            open={open}
            onClose={() => onClose()}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >

            <Formik
                initialValues={data}
                enableReinitialize={true}
            >
            {({ isSubmitting }) => (

                <Paper
                    sx={{ 
                        width: isNonMobile ? `35%` : '90%', 
                        p: 3, 
                        bgcolor: colors.primary[1000] 
                    }}
                >

                    <Form>

                        {/* HEADER DEL MODAL */}

                        <Typography variant="h3" align="center" marginBottom={1}>
                            Incidencia | {data.fecha.split('T')[0]}
                        </Typography>

                        {/* BODY DEL MODAL */}

                        <ScrollableContainer>

                            <Grid 
                                container
                                spacing={2}
                                paddingBottom={2}
                                marginTop={2}
                            >
                                
                                {/* ORDEN */}

                                <Grid item xs={12}>

                                    <TextInput
                                        name="orden"
                                        label="Orden"
                                    />

                                </Grid>

                                {/* ORIGEN */}

                                <Grid item xs={12}>

                                    <TextInput
                                        name="origen"
                                        label="Origen"
                                    />

                                </Grid>

                                {/* DESTINO */}

                                <Grid item xs={12}>

                                    <TextInput
                                        name="destino"
                                        label="Destino"
                                    />

                                </Grid>

                                {/* SUBIDO POR */}

                                <Grid item xs={12}>

                                    <TextInput
                                        name="user"
                                        label="Subido Por"
                                    />

                                </Grid>

                                {/* COMENTARIO */}

                                <Grid item xs={12}>

                                    <TextAreaInput
                                        name="comentario"
                                        label="Comentario"
                                    />

                                </Grid>

                            </Grid>

                        </ScrollableContainer>

                        {/* FOOTER DEL MODAL */}

                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>

                            <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={() => onClose()}
                            >
                                Cancelar
                            </Button>

                        </Box>

                    </Form>

                </Paper>

            )}
            </Formik>

        </Modal>

    )

}

export default ModalConsultarIncidencia