import React from 'react';
import { Button, Modal, Paper, Typography, Box, Container, Grid  } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {sendFormData} from '../../../../config/axios';
import Swal from 'sweetalert2';
import { styled } from '@mui/system'
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';
import ImageIcon from '@mui/icons-material/Image';

// COMPONENTES
import TextInput from '../../../../components/inputs/TextInput';
import MyPhoneInput from '../../../../components/inputs/MyPhoneInput';
import MyInputFile from '../../../../components/inputs/MyInputFile';

const CrearOperador = ({open, onClose, refresh}) => {

    // ===============================================
    // BODY DEL MODAL
    // ===============================================

    const ScrollableContainer = styled(Container)({
        maxHeight: '40vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });

    // ===============================================
    // VALIDAR SI SE VE DESDE UN MOVIL
    // ===============================================

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = async (values) => {

        const url = 'operadores/crear';

        const result = await sendFormData(url, values);

        if(result.success === true){

            onClose();

            refresh();

            Swal.fire({
                icon: 'success',
                title: result.message,
            })

        }

    }

    // ===============================================

    return (

        <Modal
            open={open}
            onClose={onClose}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >

            <Formik
                initialValues={{
                    nombres: '', 
                    apellidos: '', 
                    telefono: '',
                    ruta: ''
                }}
                validationSchema={Yup.object({
                    nombres: Yup.string().required('Requerido'),
                    apellidos: Yup.string().required('Requerido'),
                    ruta: Yup.mixed()
                    .test('fileType', 'Formato de archivo no válido', (value) => {
                    return value && ['image/png', 'image/jpeg', 'image/jpg'].includes(value.type);
                    })
                    .test('fileSize', 'El archivo es demasiado grande', (value) => {
                    return value && value.size <= 10485760; // 10MB
                    })
                })}
                onSubmit={(values) => {
                    handleSubmit(values)
                }}
            >
            {({ values, isSubmitting }) => (

                <Paper
                    sx={{ 
                        width: isNonMobile ? `50%` : '90%', 
                        p: 3, 
                        bgcolor: colors.primary[1000] 
                    }}
                >

                    <Form encType='multipart/form-data'>

                        {/* HEADER DEL MODAL */}

                        <Typography variant="h3" align="center" marginBottom={1}>
                            Agregar Operador
                        </Typography>

                        {/* BODY DEL MODAL */}

                        <Grid
                            container
                            spacing={2}
                            paddingBottom={2}
                            marginTop={2}
                        >

                            {/* SECCION PARA EL FORMULARIO */}

                            <Grid item md={7} xs={12}>

                                <Grid container spacing={2}>

                                    {/* NOMBRE DEL OPERADOR */}

                                    <Grid item xs={12}>

                                        <TextInput
                                            name="nombres"
                                            label="Nombre Operador"
                                        />

                                    </Grid>

                                    {/* APELLIDO DEL OPERADOR */}

                                    <Grid item xs={12}>

                                        <TextInput
                                            name="apellidos"
                                            label="Apellido Operador"
                                        />

                                    </Grid>

                                    {/* TELEFONO DEL OPERADOR */}

                                    <Grid item xs={12}>

                                        <MyPhoneInput
                                            name="telefono"
                                            label="Telefono Operador"
                                        />

                                    </Grid>

                                    {/* FOTO DEL OPERADOR */}

                                    <Grid item xs={12}>

                                        <MyInputFile
                                            name="ruta"
                                            label="Foto Del Operador"
                                        />

                                    </Grid>

                                </Grid>

                            </Grid>

                            {/* SECCION PARA LA FOTO */}

                            <Grid item md={5} xs={12}>

                                <ScrollableContainer>

                                {
                                    values.ruta !== ''
                                    ?
                                    (
                                        <img
                                            src={URL.createObjectURL(values.ruta)}
                                            alt="Imagen seleccionada"
                                            style={{ 
                                                width: '100%', 
                                                maxHeight: '100vh', 
                                                marginTop: '10px' 
                                            }}
                                        />
                                    )
                                    :
                                    (
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>

                                            <ImageIcon
                                                sx={{ 
                                                    fontSize: 300, 
                                                    maxHeight: '25vh', 
                                                    width: '100%'
                                                }}
                                            />

                                        </Box>
                                    )
                                }

                                </ScrollableContainer>

                            </Grid>

                        </Grid>

                        {/* FOOTER DEL MODAL */}

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>

                            <Button variant="contained" color="primary" onClick={() => onClose('crear')}>
                                Cancelar
                            </Button>

                            <Button 
                                type="submit" 
                                variant="contained" 
                                sx={{color: "white"}} 
                                color="success" 
                                startIcon={<SaveIcon />}
                                disabled={isSubmitting}
                            >
                                Guardar
                            </Button>

                        </Box>

                    </Form>

                </Paper>

            )}
            </Formik>

        </Modal>

    )

}

export default CrearOperador