import React from 'react';
import logok9 from '../../assets/img/plantillak9/k9Tlogo.png'
import Cookies from 'js-cookie';
import md5 from 'md5';
import { Alert } from "@mui/material";

// LIBRERIAS

import axios from 'axios';
import {baseURL} from '../../config/url';
import { useNavigate } from 'react-router-dom';
import { useState } from "react";

const Login = ({setShowLogin}) => {

  const navigate = useNavigate();

  // ===============================================
  // VALORES PARA EL FORMULARIO
  // ===============================================

  const [userData, setUserData] = useState({

    user: '',
    password: '',
    showWarning: false

  })

  // ===============================================
  // ACTUALIZAR VALORES DEL EMIAL Y EL PASSWORD
  // ===============================================

  const handleChange = (event) => {

    const val = event.target.value;
    const name = event.target.name;

    setUserData({
      ...userData, [name]: val
    })

  }

  // ===============================================  
  // FUNCION PARA EL SUBMIT DEL LOGIN
  // =============================================== 

  const handleLogin = () => {

    if(userData.user !== '' && userData.password !== ''){

      axios.post(`${baseURL}api/login`,{

        user: userData.user,
        password: md5(userData.password)
  
      })
      .then(result => {

        
        
        if(result.data.auth === true)
        {
          
          const modulos = result.data.modulos;
          const token = result.data.token;
          const idUser = result.data.user;

          const expires = new Date();

          expires.setDate(expires.getDate() + 1);
  
          Cookies.set('login', "1", {expires});
          Cookies.set('token', token, {expires});
          Cookies.set('menu', modulos, {expires});
          Cookies.set('idUser', idUser, {expires});

          setShowLogin(false);

          setUserData({
            ...userData, ['showWarning']: false
          })
          
          navigate('/dashboard');

        }
        else
        {console.log(result)

          setUserData({
            ...userData, ['showWarning']: true
          })

        }
  
      })
      .catch(error => {
  
        //setErr(false);
        console.log(error)
  
      })

    }

  };

    return (

        <div 
          className="login-container" 
          style={{
            background: 'rgb(0,0,0)',
            background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,0,0,1) 100%)',
            backgroundSize: '100% 100%', 
            boxShadow: "5px 10px 8px #888888"
          }}>

          <form autoComplete="off">

            <div className="login-box">

              <div className="logo-container">
                <img src={logok9} style={{width: "200px"}}/>
              </div>

              <input 
                name="user"
                type="email" 
                placeholder="Correo electrónico" className="input-field" 
                onChange={handleChange}
              />

              <input 
                name="password"
                type="password" 
                placeholder="Contraseña" 
                className="input-field"
                onChange={handleChange} 
              />

              {
                userData.showWarning
                ?
                (

                  <Alert 
                    severity="error" 
                    sx={{
                      marginBottom: '10px', 
                      width: '100%',
                      fontWeight: 'bold'
                    }}
                  >
                    Usuario o Contraseña incorrectos !
                  </Alert>

                )
                :
                null
              }

              <button className="login-button" type='button' onClick={handleLogin}>Iniciar sesión</button>

              </div>

            </form>

        </div>
        
    )

}

// Estilos CSS

const styles = `

  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .background-image {
    /* Estilos para la imagen de fondo */
  }

  .login-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
  }

  .logo-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input-field {
    width: 300px;
    height: 40px;
    margin-bottom: 10px;
    padding: 5px;
  }

  .login-button {
    width: 150px;
    height: 40px;
    background: #ff0000;
    color: #ffffff;
    border: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    border-radius: 10px
  }

`;

// Aplicar los estilos al componente
const styleSheet = document.createElement('style');
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default Login