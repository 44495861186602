import React, { useEffect, useState } from 'react';
import { Button, Typography, Box, Container, Grid  } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {api} from '../../../../config/axios';
import Swal from 'sweetalert2';
import { styled } from '@mui/system';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';

// COMPONENTES
import TextInput from '../../../../components/inputs/TextInput';
import MyNumberInput from '../../../../components/inputs/MyNumberInput';
import MySwitch from '../../../../components/inputs/MySwitch';
import MyMoneyInput from '../../../../components/inputs/MyMoneyInput';

const EditarCliente = ({ data }) => {

    // ===============================================
    // VALORES PARA SUBMIT DEL FORMULARIO
    // ===============================================

    const [formValues, setFormValues] = useState({

        nombre_comercial: '', 
        razon_social: '', 
        correo: '',
        direccion: '',
        credito: '',
        rfc: '',
        iva: '',

    });

    // ===============================================
    // BODY DEL MODAL
    // ===============================================

    const ScrollableContainer = styled(Container)({
        maxHeight: '60vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });

    // ===============================================
    // ACTUALIZAR VALORES DEL FORMULARIO
    // ===============================================

    useEffect(() => {

        if(data !== ''){

            setFormValues(data);

        }

    },[data]);

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = (values) => {

        const url = 'clientes/update';

        api.put(url, values)
        .then((response) => {

            if(response.data.success === true){

                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                })

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    // ===============================================

    return (

        <Formik
            initialValues={formValues}
            enableReinitialize={true}
            validationSchema={Yup.object({
                nombre_comercial: Yup.string().required('Requerido'),
                correo: Yup.string().email('Ingrese un correo Valido').required('Requerido'),
                iva: Yup.string().required('Requerido'),
            })}
            onSubmit={(values) => {
                handleSubmit(values)
            }}
        >

            <Form>

                {/* HEADER */}

                <Typography variant="h3" align="center" marginBottom={1}>
                    Datos Del Cliente
                </Typography>

                {/* BODY */}

                <ScrollableContainer>

                    <Grid 
                        container
                        spacing={2}
                        paddingBottom={2}
                        marginTop={2}
                    >

                        {/* NOMBRE COMERCIAL DEL CLIENTE */}

                        <Grid item md={5} xs={12}>

                            <TextInput
                                name="nombre_comercial"
                                label="Nombre Del Cliente"
                            />

                        </Grid>

                        {/* RAZON SOCIAL DEL CLIENTE */}

                        <Grid item md={5} xs={12}>

                            <TextInput
                                name="razon_social"
                                label="Razon Social Del Cliente"
                            />

                        </Grid>

                        {/* ACTIVO */}

                        <Grid item md={2} xs={6}>

                            <MySwitch
                                name="activo"
                                label="Activo"
                            />

                        </Grid>

                        {/* CORREO ELECTRONICO DEL CLIENTE */}

                        <Grid item md={6} xs={12}>

                            <TextInput
                                name="correo"
                                label="Correo Electronico"
                            />

                        </Grid>

                        {/* DIRECCION DEL CLIENTE */}

                        <Grid item md={6} xs={12}>

                            <TextInput
                                name="direccion"
                                label="Direccion"
                            />

                        </Grid>

                        {/* RFC DEL CLIENTE */}

                        <Grid item md={4} xs={12}>

                            <TextInput
                                name="rfc"
                                label="RFC"
                            />

                        </Grid>

                        {/* IVA DEL CLIENTE */}

                        <Grid item md={4} xs={6}>

                            <MyNumberInput
                                name="iva"
                                label="IVA (%)"
                            />

                        </Grid>

                        {/* CREDITO DEL CLIENTE */}

                        <Grid item xs={4}>

                            <MyMoneyInput
                                name="credito"
                                label="Credito"
                            />

                        </Grid>

                    </Grid>

                </ScrollableContainer>

                {/* FOOTER DEL MODAL */}

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>

                    <Button 
                        type="submit" 
                        variant="contained" 
                        sx={{color: "white"}} 
                        color="success" 
                        startIcon={<SaveIcon />}
                    >
                        Guardar
                    </Button>

                </Box>

            </Form>

        </Formik>

    )

}

export default EditarCliente