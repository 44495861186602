import React from 'react';
import { useField } from 'formik';
import { TextField } from '@mui/material';

const MyTextInputRequired = ({ label, ...props }) => {

    const [ field, meta ] = useField(props);

    //=============================================

    return (

        <TextField
            sx={{ width: "100%" }}
            variant="outlined"
            className="text-input"
            autoComplete="off"
            label={label}
            required
            {...field}
            {...props}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
        />

    )

}

export default MyTextInputRequired