// ===============================================
// CATALOGO DE TIPOS DE CARGA
// ===============================================

export const tiposCarga = [

    { value: '3.5 Ton', label: '3.5 Ton' },
    { value: 'Caja Seca', label: 'Caja Seca' },
    { value: 'Caja Refrigerada', label: 'Caja Refrigerada' },
    { value: 'Cama Baja', label: 'Cama Baja' },
    { value: 'Plataforma 48', label: 'Plataforma 48' },
    { value: 'Plataforma 53', label: 'Plataforma 53' },
    { value: 'Rabon', label: 'Rabon' },
    { value: 'Sprinter', label: 'Sprinter' },
    { value: 'Semi Low Boy', label: 'Semi Low Boy' },
    { value: 'Transfer', label: 'Transfer' },
    { value: 'Van', label: 'Van' },

];

// ===============================================
// CATALOGO DE TIPOS DE OPERACION
// ===============================================

export const tiposOperacion = [

    { value: 'D2D', label: 'D2D' },
    { value: 'Hot Shot', label: 'Hot Shot' },
    { value: 'Cruce Fronterizo', label: 'Cruce Fronterizo' },
    { value: 'Nacional', label: 'Nacional' },
    { value: 'Americano', label: 'Americano' },
    { value: 'Consolidado', label: 'Consolidado' },
    { value: 'Paqueteria', label: 'Paqueteria' },
    { value: 'Flete Sobredimensionado', label: 'Flete Sobredimensionado' },
    { value: 'Flete Con Sobrepeso', label: 'Flete Con Sobrepeso' },

];