import React from 'react';
import { useField } from 'formik';
import { TextField } from "@mui/material";

const MyHourInput = ({ label, ...props }) => {

    const [field, meta] = useField(props);

    // Función de validación para asegurarse de que se ingrese una hora válida (formato HH:mm)
    const validateTime = (value) => {
        const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
        if (!regex.test(value)) {
            return 'Formato de hora inválido (HH:mm)';
        }
        return undefined; // Sin errores de validación
    };

    return (
        <TextField
            sx={{ width: "100%"}}
            variant="outlined"
            className="time-input"
            autoComplete="off"
            label={label}
            type="time" // Establece el tipo de entrada a "time"
            {...field}
            {...props}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            inputProps={{
                step: 300, // Intervalo de 5 minutos
            }}
            onBlur={(e) => {
                // Asegúrate de que el valor sea válido antes de actualizar el campo de Formik
                const value = e.target.value;
                const error = validateTime(value);
                if (!error) {
                    field.onBlur(e);
                }
            }}
        />
    );

}

export default MyHourInput