import React, { useEffect, useState } from 'react';
import { useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import { api } from '../../../config/axios';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';

// COMPONENTES
import MiTabla from '../../../components/plantilla/MiTabla';
import ModalCrearIncidencias from './modales/ModalCrearIncidencias';
import ModalEditarIncidencias from './modales/ModalEditarIncidencias';

const Incidencias = ({ orderData }) => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // FUNCION PARA BUSCAR TRACTORES
    // ===============================================

    const [data, setData] = useState([]);

    const buscarIncidentes = async () => {

        const response = await api.get(`operadores/incidencias/orden/${orderData.id}`);

        if(response.data !== "Sin Resultados"){

            setData(response.data);

        }else{

            setData([]);

        }

    }

    // ===============================================
    // COLUMNAS PARA LA TABLA
    // ===============================================

    const columns = [

        {
            field: "operador",
            headerName: "Operador",
            flex: 1,
            cellClassName: "name-column--cell",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "comentario",
            headerName: "Comentario",
            flex: 2,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "timestamp",
            headerName: "Fecha",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => cell.row.timestamp.split('T')[0]
        },

    ];

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR MODALES
    // ===============================================

    const [modal, setModal] = useState({

        crear: false,
        editar: false

    })

    // -----------------------------------------------

    const handleOpenModal = (target) => {

        setModal({ ...modal, [target]: true});

    };

    // -----------------------------------------------
    
    const handleCloseModal = (target) => {

        setModal({ ...modal, [target]: false});

    };

    // ===============================================
    // FUNCION PARA EDITAR REGISTRO
    // ===============================================

    const [formValues, setFormValues] = useState([])

    const handleRowClick = (params) => {

        setFormValues(params.row);

        handleOpenModal('editar');

    }

    // ===============================================
    // USE EFFECT PARA LLAMAR A LAS FUNCIONES
    // ===============================================

    useEffect(() => {

        buscarIncidentes();

    },[]);

    // ===============================================

    return (

        <React.Fragment>

            {/* BOTON PARA ABRIR MODAL */}

            <Button variant="contained" 
                sx={{
                        fontWeight:"bold",
                        backgroundColor:colors.redAccent[400]
                }} 
                startIcon={<AddCircleIcon />}
                onClick={() => handleOpenModal('crear')}
            >
                Agregar Incidencia
            </Button>

            {/* TABLA */}

            <MiTabla
                data={data}
                columns={columns}
                rowClick={handleRowClick}
            />

            {/* MODAL CREAR INCIDENCIA */}

            <ModalCrearIncidencias
                open={modal.crear}
                onClose={handleCloseModal}
                refresh={buscarIncidentes}
                idOrden={orderData.id}
                idOperador={orderData.rel_operador}
            />

            {/* MODAL EDITAR INCIDENCIAS */}

            <ModalEditarIncidencias
                open={modal.editar}
                onClose={handleCloseModal}
                refresh={buscarIncidentes}
                idOperador={orderData.rel_operador}
                formValues={formValues}
            />

        </React.Fragment>

    )

}

export default Incidencias
