import React, { useEffect, useState } from 'react';
import { Box, useTheme, Button, Grid, Typography, Card, CardHeader, CardActions, IconButton, TextField } from "@mui/material";
import { tokens } from "../../../theme";
import {api, fileDownload} from '../../../config/axios';
import { marcarURL } from '../../../config/functions';
import { formatDate } from '../../../config/functions';

// ICONOS
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';

//COMPONENTES
import Header from '../../../components/Header';
import ModalSubirDocumentos from './ModalSubirDocumentos';

const Documentos = () => {

    // ===============================================
    // COLORES PARA ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // FUNCION PARA BUSCAR DOCUMENTOS
    // ===============================================

    const [data, setData] = useState([]);

    const buscarDocumentos = async () => {

        const response = await api.get('documentos');

        if(response.data !== "Sin Resultados"){

            setData(response.data);

            setDocumentos(response.data);

        }else{

            setData([]);

        }

    }

    // ===============================================
    // FUNCION PARA ABRIR O CERRAR MODAL
    // ===============================================

    const [modal, setModal] = useState(false);

    // ===============================================
    // FUNCION PARA DESCARGAR EL ARCHIVO
    // ===============================================

    const handleDownload = (ruta) => {

        const file = ruta.split('/');
        const fileName = file[file.length -1];

        const data = {ruta: ruta};

        const endpoint = 'documentos/download';

        fileDownload.post(endpoint, data)
        .then(response => {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

        })
        .catch(error => {
            // Manejar errores de la solicitud
            console.error(error);
        });

    }

    // ===============================================
    // FUNCION PARA FILTRAR DOCUMENTOS
    // ===============================================

    const [documentos, setDocumentos] = useState([]);

    const filtrarDocs = (value) => {

        if(data.length > 0){

            const filtro = data.filter(obj => obj.descripcion.toLowerCase().includes(value) || obj.descripcion.toLowerCase() === value.toLowerCase());

            setDocumentos(filtro)

        }

    }

    // ===============================================
    // USE EFFECT PARA LLAMAR DATOS
    // ===============================================

    useEffect(() => {

        marcarURL('documentos');

        buscarDocumentos();

    },[]);

    // ===============================================

    return (

        <Box m="20px">

            {/* HEADER */}

            <Header title="Documentos" subtitle="Documentos Internos"/>

            <Box display={'flex'} justifyContent={'space-between'}>

                {/* BOTON PARA ABRIR MODAL */}

                <Button 
                    variant="contained" 
                    sx={{
                            fontWeight:"bold",
                            backgroundColor:colors.redAccent[400]
                    }} 
                    startIcon={<AddCircleIcon />}
                    onClick={() => setModal(true)}
                >
                    Agregar Documento
                </Button>

                {/* INPUT PARA FILTRAR */}

                <Box sx={{ display: 'flex', alignItems: 'flex-end', marginRight: '20px' }}>
                    <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <TextField 
                        id="input-with-sx" 
                        label="Buscar Documento" 
                        variant="standard" 
                        onChange={(event) => filtrarDocs(event.target.value)}
                    />
                </Box>

            </Box>

            {/* SECCION PARA TARJETAS */}

            <Grid container spacing={2} padding={3}>

                {
                    documentos.map((file, index) => (

                        <Grid item md={4} xs={12} key={index}>

                            <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                                {/* HEADER DE LA TARJETA */}

                                <CardHeader
                                    title={
                                        <Typography variant="h4" align="center">
                                            {file.descripcion}
                                        </Typography>
                                    }
                                    subheader={
                                        <>
                                            <Typography variant="h6" align="center">
                                                Subido el {formatDate(file.timestamp)}
                                            </Typography>
                                        </>
                                    }
                                />

                                {/* VALIDAMOS ICONO DE LA TARJETA */}

                                <IconButton >
                                    <DescriptionIcon 
                                        sx={{ fontSize: 120 }}
                                    />
                                </IconButton>

                                {/* FOOTER DE LA TARJETA */}

                                <CardActions disableSpacing>
                                    <IconButton onClick={() => handleDownload(file.ruta)}> 
                                        <DownloadIcon/>
                                    </IconButton>
                                </CardActions>
                            </Card>

                        </Grid>

                    ))
                }

            </Grid>

            {/* MODAL PARA AGREGAR DOCUMENTO */}

            <ModalSubirDocumentos
                open={modal}
                onClose={() => setModal(false)}
                refresh={buscarDocumentos}
            />

        </Box>

    )

}

export default Documentos