import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';

// ICONOS
import PaidIcon from '@mui/icons-material/Paid';
import EditRoadIcon from '@mui/icons-material/EditRoad';

const DashboardOperador = () => {

    const navigate = useNavigate();

    return (

        <Box m="20px">

            <Typography variant="h1" align="center" mb={2}>
                <b>Menu</b>
            </Typography>

            <Grid container spacing={2}>

                {/* SEGUIMIENTO DE VIAJE */}

                <Grid item xs={12}>

                    <Card 
                        sx={{ 
                            width: '100%', 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center', 
                            justifyContent: 'center' 
                        }}
                    >

                        <IconButton >
                            <EditRoadIcon 
                                sx={{ fontSize: 120, marginTop: 3 }}
                            />
                        </IconButton>
                        
                        <Typography variant="h2" align="center" mb={2}>
                            <b>Seguimiento Viaje</b>
                        </Typography>

                    </Card>

                </Grid>

                {/* VIATICOS DEL OPERADOR */}

                <Grid item xs={12}>

                    <Card 
                        sx={{ 
                            width: '100%', 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center', 
                            justifyContent: 'center' 
                        }}
                        onClick={() => navigate('/viaticos_operador')}
                    >

                        <IconButton>
                            <PaidIcon 
                                sx={{ fontSize: 120, marginTop: 3 }}
                            />
                        </IconButton>
                        
                        <Typography variant="h2" align="center" mb={2}>
                            <b>Viaticos</b>
                        </Typography>

                    </Card>

                </Grid>

            </Grid>

        </Box>

    )
    
}

export default DashboardOperador

// viaticos_operador URL