import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Box, Grid, BottomNavigation, BottomNavigationAction } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from '../../theme';
import { api } from '../../config/axios';
import { lpad } from '../../config/functions';

// ICONOS
import EditRoadIcon from '@mui/icons-material/EditRoad';
import PinDropIcon from '@mui/icons-material/PinDrop';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import AssignmentIcon from '@mui/icons-material/Assignment';

// COMPONENTES
import EditarOrdenTransporte from './ordenes/EditarOrdenTransporte';
import Incidencias from './incidencias/Incidencias';
import Stops from './stops/Stops';
import CartaRetiro from './cartaRetiro/CartaRetiro';

const ControlOrdenes = () => {

    // ===============================================
    // OBTENER INFORMACION DEL CURSO
    // ===============================================

    const location = useLocation();
    const { idOrden } = location.state;

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // -----------------------------------------------

    const fontColor = colors.redAccent[500];
    const iconColor = colors.redAccent[500];

    // ===============================================
    // CATALOGO DE COMPONENTES
    // ===============================================

    const views = {

        orden: EditarOrdenTransporte,
        stops: Stops,
        incidencias: Incidencias,
        cartaRetiro: CartaRetiro

    }

    // ===============================================
    // OPCION DE COMPONENTE 
    // ===============================================

    const [opcion, setOpcion] = useState('orden');

    // ===============================================
    // COMPONENTE DINAMICO
    // ===============================================

    const ComponenteActual = views[opcion];

    // ===============================================
    // FUNCION PARA BUSCAR ORDEN POR ID
    // ===============================================

    const [ordenData, setOrdenData] = useState({});

    // -----------------------------------------------

    const buscarOrden = async () => {

        const response = await api.get(`transporte/ordenes/${idOrden}`);

        if(response.data !== "Sin Resultados"){

            let data = response.data[0];

            data.fecha_cita = data.fecha_cita.split('T')[0];

            setOrdenData(data);

        }

    }

    // ===============================================
    // USE EFFECT PARA LLAMAR FUNCIONES
    // ===============================================

    useEffect(() => {

        buscarOrden();

    },[idOrden]);

    // ===============================================

    return (

        <Box m="20px">

            <Grid container spacing={2}>

                {/* HEADER */}

                <Grid item xs={12}>
                
                    <Typography variant="h1" align="center" fontWeight={'bold'}>
                        Orden - T{lpad(idOrden, 5, "0")}
                    </Typography>

                </Grid>

                {/* SECCION PARA LOS MENUS */}

                <Grid item xs={12}>

                    <BottomNavigation
                        showLabels
                        value={opcion}
                        onChange={(event, newValue) => {
                            setOpcion(newValue)
                        }}
                        sx={{borderRadius: '10px'}}
                    >
                        {/* SECCION DE ORDENES */}
                        <BottomNavigationAction
                            label="Orden"
                            value='orden'
                            icon={
                                <EditRoadIcon 
                                    style={{ 
                                        color: opcion === 'orden' 
                                        ? 
                                        iconColor 
                                        : 
                                        'inherit' 
                                    }} 
                                />}
                            style={{ 
                                color: opcion === 'orden' 
                                ? 
                                fontColor 
                                : 
                                'inherit' 
                            }}
                        />
                        {/* SECCION DE STOPS */}
                        <BottomNavigationAction
                            label="Stops"
                            value='stops'
                            icon={
                                <PinDropIcon 
                                    style={{ 
                                        color: opcion === 'stops' 
                                        ? 
                                        iconColor 
                                        : 
                                        'inherit' 
                                    }} 
                                />}
                            style={{ 
                                color: opcion === 'stops' 
                                ? 
                                fontColor 
                                : 
                                'inherit' 
                            }}
                        />
                        {/* SECCION DE INCIDENTES */}
                        <BottomNavigationAction
                            label="Incidencias"
                            value='incidencias'
                            icon={
                                <AnnouncementIcon 
                                    style={{ 
                                        color: opcion === 'incidencias' 
                                        ? 
                                        iconColor 
                                        : 
                                        'inherit' 
                                    }} 
                                />}
                            style={{ 
                                color: opcion === 'incidencias' 
                                ? 
                                fontColor 
                                : 
                                'inherit' 
                            }}
                        />
                        {/* SECCION DE CARTAS DE INSTRUCCION */}
                        <BottomNavigationAction
                            label="C. Retiro"
                            value='cartaRetiro'
                            icon={
                                <AssignmentIcon 
                                    style={{ 
                                        color: opcion === 'cartaRetiro' 
                                        ? 
                                        iconColor 
                                        : 
                                        'inherit' 
                                    }} 
                                />}
                            style={{ 
                                color: opcion === 'cartaRetiro' 
                                ? 
                                fontColor 
                                : 
                                'inherit' 
                            }}
                        />
                    </BottomNavigation>

                </Grid>

                {/* AREA DEL COMPONENTE A MOSTRAR */}

                <Grid item xs={12}>

                    <ComponenteActual
                        orderData={ordenData}
                        refresh={buscarOrden}
                    />

                </Grid>

            </Grid>

        </Box>

    )

}

export default ControlOrdenes