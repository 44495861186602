import React, { useState } from 'react';
import { Button, Modal, Paper, Typography, Box, Container, Grid, Select, MenuItem, ListSubheader, FormControl, InputLabel, Divider, IconButton, Alert } from "@mui/material";
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import {api} from '../../../../config/axios';
import Swal from 'sweetalert2';
import { styled } from '@mui/system'
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';
import { modulos } from '../../../../data/modulosk9';

// ICONOS
import SaveIcon from '@mui/icons-material/Save';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

// COMPONENTES
import TextInput from '../../../../components/inputs/TextInput';
import MySelect from '../../../../components/inputs/MySelect';
import MyTextInputRequired from '../../../../components/inputs/MyTextInputRequired';

const ModalCrearPerfil = ({open, onClose, refresh}) => {

    // ===============================================
    // BODY DEL MODAL
    // ===============================================

    const ScrollableContainer = styled(Container)({
        maxHeight: '60vh', // Ajusta esta altura máxima como desees
        overflowY: 'auto',
    });

    // ===============================================
    // VALIDAR SI SE VE DESDE UN MOVIL
    // ===============================================

    const isNonMobile = useMediaQuery("(min-width:600px)");

    // ===============================================
    // COLORES PARA LOS ESTILOS
    // ===============================================

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ===============================================
    // ESTADO QUE GUARDA EL MODULO ESCOGIDO
    // ===============================================

    const [moduloSeleccionado, setModuloSeleccionado] = useState('');

    // ===============================================
    // SECCION PARA MANEJAR EL ESTADO DEL AVISO
    // ===============================================

    const [showAlert, setShowAlert] = useState({

        status: false,
        texto: ''

    });

    // -----------------------------------------------

    const mostrarAlerta = (texto) => {

        // MOSTRAMOS LA ALERTA EN EL FORMULARIO

        setShowAlert({
            ...showAlert, 
            ['status']: true,
            ['texto']: texto
        });

        // OCULTAMOS LA ALERTA 5 SEGUNDOS DESPUES DE MOSTRARLA

        setTimeout(function() {

            setShowAlert({
                ...showAlert, 
                ['status']: false,
                ['texto']: ''
            });

        }, 5000);

    }

    // ===============================================
    // FUNCION PARA HACER SUBMIT
    // ===============================================

    const handleSubmit = (values) => {

        const url = 'perfiles/crear';

        values.modulos = JSON.stringify(values.modulos);

        api.post(url, values)
        .then((response) => {

            if(response.data.success === true){

                onClose('crear');

                refresh();

                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                })

            }

        })
        .catch((error) => {
            console.log("ocurrio un error: " +error);
        })

    }

    // ===============================================

    return (

        <Modal
            open={open}
            onClose={onClose}
            BackdropProps={{onClick: (event) => event.stopPropagation}}
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' ,
            }}
        >

            <Formik
                initialValues={{
                    nombre_perfil: '', 
                    modulos: [], 
                }}
                validationSchema={Yup.object({
                    nombre_perfil: Yup.string().required('Requerido'),
                    modulos: Yup.array().of(
                        Yup.object().shape({
                            modulo: Yup.string().required('Requerido'),
                            permiso: Yup.string().required('Requerido'),
                        })
                    )
                })}
                onSubmit={(values) => {
                    handleSubmit(values)
                }}
            >
            {({ values, isSubmitting }) => (

                <Paper
                    sx={{ 
                        width: isNonMobile ? `50%` : '90%', 
                        p: 3, 
                        bgcolor: colors.primary[1000] 
                    }}
                >

                    <Form>

                        {/* HEADER DEL MODAL */}

                        <Typography variant="h3" align="center" marginBottom={1}>
                            Agregar Perfil
                        </Typography>

                        {/* BODY DEL MODAL */}

                        <ScrollableContainer>

                            <Grid 
                                container
                                spacing={2}
                                paddingBottom={2}
                                marginTop={2}
                            >

                                {/* NOMBRE DEL PERFIL */}

                                <Grid item xs={5}>

                                    <TextInput
                                        name="nombre_perfil"
                                        label="Nombre Del Perfil"
                                    />

                                </Grid>

                                {/* SELECT PARA MODULOS */}

                                <Grid item xs={5}>

                                    <FormControl sx={{ width: '100%' }}>

                                        <InputLabel htmlFor="grouped-select">Seleccione un Modulo</InputLabel>

                                        <Select 
                                            id="grouped-select" 
                                            label="Grouping"
                                            value={moduloSeleccionado}
                                            renderValue={(value) => value.split('.')[0]}
                                            onChange={(event) => setModuloSeleccionado(event.target.value)}
                                        >

                                            {
                                                modulos.map((item, index) => (

                                                    item.type === 'subheader'
                                                    ?
                                                    (
                                                        <ListSubheader key={index}>{item.label}</ListSubheader>
                                                    )
                                                    :
                                                    (
                                                        <MenuItem key={index} value={`${item.modulo}.${item.seccion}`}>{item.modulo}</MenuItem>
                                                    )

                                                ))
                                            }

                                        </Select>

                                    </FormControl>

                                </Grid>

                                {/* SECCION PARA LOS INPUTS DINAMICOS */}

                                <FieldArray name="modulos">
                                {({ remove, push }) => (

                                    <>

                                    {/* BOTON PARA PREGUNTAR */}

                                    <Grid item xs={2} sx={{display: 'flex', justifyContent: 'center'}}>

                                        <Button 
                                            type="button" 
                                            variant="contained" 
                                            sx={{color: "white", backgroundColor: '#1CCAF4'}} 
                                            onClick={() => {

                                                // FILTRAMOS VALORES PARA SACAR LOS REPETIDOS

                                                let existeDato = values.modulos.find(function(objeto){

                                                    return objeto.modulo === moduloSeleccionado.split('.')[0];

                                                });

                                                // VALIDAMOS SI EXISTE EL REGISTRO

                                                if(existeDato){

                                                    mostrarAlerta('Este modulo ya fue agregado !')

                                                }
                                                // VALIDAMOS SI ESTA VACIO

                                                else if(moduloSeleccionado === ''){

                                                    mostrarAlerta('Selecciona Un Modulo para agregarlo !');

                                                }

                                                // SI PASAMOS VALIDACIONES 
                                                
                                                else{

                                                    push({
                                                        seccion: moduloSeleccionado.split('.')[1],
                                                        modulo: moduloSeleccionado.split('.')[0],
                                                        permiso: '',
                                                    });

                                                }
                                                
                                            }}
                                        >
                                            <AddCircleIcon />
                                        </Button>

                                    </Grid>

                                    {/* ALERTA PARA MOSTRAR AVISOS */}

                                    {
                                        showAlert.status && (

                                            <Grid item xs={12}>

                                                <Alert severity="warning">{showAlert.texto}</Alert>

                                            </Grid>

                                        )
                                    }

                                    {/* AREA PARA INPUTS DINAMICOS */}

                                    {

                                        Array.isArray(values.modulos) && values.modulos.map((item, index) => (

                                            <React.Fragment key={index}>

                                                {/* DIVISOR */}

                                                <Grid item xs={12}>

                                                    <Divider></Divider>

                                                </Grid>

                                                {/* INPUT PARA MODULO SELECCIONADO */}

                                                <Grid item xs={5}>

                                                    <MyTextInputRequired 
                                                        label="Modulo" 
                                                        name={`modulos.${index}.modulo`}
                                                    />

                                                </Grid>

                                                {/* INPUT PARA SELECCIONAR TIPO DE PERMISO */}

                                                <Grid item xs={5}>

                                                    <MySelect
                                                        label="Permiso"
                                                        name={`modulos.${index}.permiso`}
                                                    >
                                                        <MenuItem value={1}>Visor</MenuItem>
                                                        <MenuItem value={2}>Capturista</MenuItem>
                                                        <MenuItem value={3}>Administrador</MenuItem>
                                                    </MySelect>

                                                </Grid>

                                                {/* BOTON PARA ELIMINAR MODULO */}

                                                <Grid item xs={2} sx={{display: 'flex', justifyContent: 'center'}}>

                                                    <IconButton
                                                        onClick={() => remove(index)}
                                                    >
                                                        <DeleteIcon/>
                                                    </IconButton>

                                                </Grid>

                                            </React.Fragment>

                                        ))

                                    }

                                    </>
      
                                )}
                                </FieldArray>

                            </Grid>

                        </ScrollableContainer>

                        {/* FOOTER DEL MODAL */}

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>

                            <Button variant="contained" color="primary" onClick={() => onClose('crear')}>
                                Cancelar
                            </Button>

                            <Button 
                                type="submit" 
                                variant="contained" 
                                sx={{color: "white"}} 
                                color="success" 
                                startIcon={<SaveIcon />}
                                disabled={isSubmitting}
                            >
                                Guardar
                            </Button>

                        </Box>

                    </Form>

                </Paper>

            )}
            </Formik>

        </Modal>

    )

}

export default ModalCrearPerfil