import React from 'react';
import { useField } from 'formik';
import { TextField } from "@mui/material";
import { NumericFormat } from 'react-number-format';

const MyMoneyInput = ({ label, ...props }) => {

    const [field, meta] = useField(props);

    return (

        <NumericFormat
            sx={{width: '100%'}}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            allowNegative={false}
            prefix="$"
            label={label}
            variant="outlined"
            autoComplete="off"
            customInput={TextField}
            {...field}
            {...props}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
        />

    )

}

export default MyMoneyInput